/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// database
import { realtimeGetBookings, updateBooking } from "../../database/booking";

// components
import { MdCancel } from "react-icons/md";
import { BiSolidDetail } from "react-icons/bi";
import { IoIosAddCircle, IoIosCheckmarkCircle } from "react-icons/io";
import Table from "../../components/table/Table";
import Header from "../../components/header/Header";
import MainBox from "../../components/layout/MainBox";
import InputSelection from "../../components/form/InputSelection";

// utils
import { RootContext } from "../app/Root";
import { getNMonthList } from "../../services/dateJs";

const MonthListMain = getNMonthList(3, 1);

const BookingTableConfig = [
  {
    head: "วันเวลาจองคิว",
    type: "datetime",
    data: "bookingDatetime",
  },
  {
    head: "ชื่อลูกค้า",
    type: "text",
    data: "customer.name",
  },
  {
    head: "เบอร์ลูกค้า",
    type: "text",
    data: "customer.phone",
  },
  {
    head: "ไลน์ลูกค้า",
    type: "uidAvatar",
    data: "customer.uid",
    align: "center",
  },
  {
    head: "ประเภทรถ",
    type: "text",
    data: "car.type",
  },
  {
    head: "สีรถ",
    type: "text",
    data: "car.color",
  },
  {
    head: "ทะเบียน",
    type: "text",
    data: "car.plate",
  },
  {
    head: "บริการหลัก",
    type: "text",
    data: "service.main.name.th",
  },
  {
    head: "บริการเพิ่มเติม",
    type: "list",
    data: "service.additional",
    inner: "name.th",
  },
  {
    head: "หมายเหตุ",
    type: "text",
    data: "note",
  },
  {
    head: "รหัสการจอง",
    type: "text",
    data: "id",
  },
  {
    head: "สถานะ",
    type: "text",
    data: "status",
  },
];

export default function AllBooking() {
  const navigate = useNavigate();
  const rootContext = useContext(RootContext);

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { openConfirm } = rootContext.confirm;
  const { isLogin, userData } = rootContext.user;
  const { incLoading, decLoading } = rootContext.loading;

  const [bookingData, setBookingData] = useState(null);
  const [displayBookingData, setDisplayBookingData] = useState([]);

  let dateRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(MonthListMain[3]);

  // ------------- realtime database ----------------
  useEffect(() => {
    if (isLogin && userData?.type === "employee") {
      incLoading("realtimeGetBookings");
      realtimeGetBookings(
        setBookingData,
        selectedDate.startDate,
        selectedDate.endDate
      );
    }
  }, [isLogin, selectedDate]);

  useEffect(() => {
    if (bookingData) {
      decLoading("realtimeGetBookings");
      setDisplayBookingData(bookingData);
    }
  }, [bookingData]);

  // ------------- function for table options ----------------
  const confirmBooking = async (booking) => {
    const { id: bookingId } = booking;
    const resConfirm = await openConfirm(
      "ยืนยันการจอง",
      `คุณต้องการยืนยันการจองรายการรหัส ${bookingId} ใช่หรือไม่?`
    );
    if (!resConfirm) return;

    incLoading("confirmBooking");
    const resConfirmBooking = await updateBooking(bookingId, {
      status: "confirmed",
    });
    decLoading("confirmBooking");

    if (!resConfirmBooking.ok) {
      return pushToast(`ไม่สามารถยืนยันการจองรายการรหัส ${bookingId}`, "error");
    } else {
      openAlert(
        "ยืนยันการจองสำเร็จ",
        `ยืนยันการจองรายการรหัส ${bookingId} สำเร็จ`
      );
    }
  };

  const cancelBooking = async (booking) => {
    const { id: bookingId } = booking;
    const resCancel = await openConfirm(
      "ยกเลิกการจอง",
      `คุณต้องการยกเลิกการจองรายการรหัส ${bookingId} ใช่หรือไม่?`
    );
    if (!resCancel) return;

    incLoading("cancelBooking");
    const resCancelBooking = await updateBooking(bookingId, {
      status: "canceled",
    });
    decLoading("cancelBooking");

    if (!resCancelBooking.ok) {
      return pushToast(`ไม่สามารถยกเลิกการจองรายการรหัส ${bookingId}`, "error");
    } else {
      openAlert(
        "ยกเลิกการจองสำเร็จ",
        `ยกเลิกการจองรายการรหัส ${bookingId} สำเร็จ`
      );
    }
  };

  const navigateToAddBill = (booking) => {
    const { id: bookingId } = booking;
    navigate(`/bill/add/${bookingId}`);
  };

  const navigateToBookingDetail = (booking) => {
    const { id: bookingId } = booking;
    navigate(`/booking/${bookingId}`);
  };

  const checkDisabledConfirmBooking = (booking) => {
    return booking.status === "confirmed";
  };

  const checkDisabledCancelBooking = (booking) => {
    return booking.status === "canceled";
  };

  return (
    <div className=" min-h-screen">
      <Header
        title="ระบบจัดการการจอง"
        requireType="employee"
        userType={userData?.type}
      />

      <div className="pt-36 pb-20">
        <MainBox>
          <InputSelection
            ref={dateRef}
            list={MonthListMain}
            selected={[selectedDate, setSelectedDate]}
          />
        </MainBox>

        <MainBox>
          <Table
            pathAdd="/booking"
            data={displayBookingData}
            config={BookingTableConfig}
            option={{
              head: {
                type: "customer",
                data: "customer",
              },
              list: [
                {
                  icon: BiSolidDetail,
                  text: "ดูรายละเอียด",
                  onClick: navigateToBookingDetail,
                },
                {
                  icon: IoIosAddCircle,
                  text: "เพิ่มไปรายการบิล",
                  onClick: navigateToAddBill,
                },
                {
                  icon: IoIosCheckmarkCircle,
                  text: "ยืนยันการจอง",
                  onClick: confirmBooking,
                  onDisabled: checkDisabledConfirmBooking,
                },

                {
                  icon: MdCancel,
                  text: "ยกเลิกการจอง",
                  onClick: cancelBooking,
                  onDisabled: checkDisabledCancelBooking,
                },
              ],
            }}
          />
        </MainBox>
      </div>
    </div>
  );
}
