/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import ServiceDetail from "../../../components/ServiceDetail";
import CarDetail from "./CarDetail";
import BookingInfo from "./BookingInfo";
import { convertTimestampToDate } from "../../../utils/datetime";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const heads = ["ข้อมูลผู้จอง", "ข้อมูลรถ", "ข้อมูลบริการ"];

export default function BookingDetailTab(props) {
  const { bookingData, userData, handleCancel } = props;

  const [totalPrice, setTotalPrice] = useState({
    total: 0,
    discount: 0,
    net: 0,
  });

  const calculateTotalPrice = () => {
    let total = 0;
    let discount = 0;
    let net = 0;

    if (bookingData?.service.main.id !== "none") {
      total += bookingData?.service.main.price[bookingData?.car.type];
    }

    bookingData?.service?.additional.forEach((service) => {
      total += service.price[bookingData?.car.type];
    });

    if (bookingData?.service.promotion.id !== "none") {
      if (bookingData?.service.promotion.type === "discount") {
        if (bookingData?.service.promotion.discount.type === "price") {
          discount -= bookingData?.service.promotion.discount.value;
        } else if (bookingData?.service.promotion.discount.type === "percent") {
          discount -=
            total * (bookingData?.service.promotion.discount.value / 100);
        }
      }
    }

    net = total + discount;

    setTotalPrice({
      total,
      discount,
      net,
    });
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [bookingData]);

  return (
    <Tab.Group>
      <Tab.List className="text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 flex">
        {heads.map((head, idx) => (
          <Tab
            key={idx}
            className={({ selected }) =>
              classNames(
                "inline-block w-full p-4 bg-gray-50 hover:bg-gray-100 focus:outline-none",
                selected ? " text-red-700 " : ""
              )
            }>
            {head}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="mt-2">
        <Tab.Panel key={0}>
          <BookingInfo
            datetime={convertTimestampToDate(bookingData?.datetime)}
            bookingDatetime={convertTimestampToDate(
              bookingData?.bookingDatetime
            )}
            status={bookingData?.status}
            userData={userData}
            customer={bookingData?.customer}
            handleCancel={handleCancel}
            note={bookingData?.note}
          />
        </Tab.Panel>
        <Tab.Panel key={1}>
          <CarDetail car={bookingData?.car} />
        </Tab.Panel>
        <Tab.Panel key={2}>
          <ServiceDetail
            mainService={bookingData?.service.main}
            additionalService={bookingData?.service.additional}
            promotion={bookingData?.service.promotion}
            carType={bookingData?.car.type}
            totalPrice={totalPrice}
          />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}
