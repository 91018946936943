/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import liff from "@line/liff";

// components
import MultiToast from "../../components/layout/MultiToast";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/NavBar";
import Alert from "../../components/layout/Alert";
import FooterBar from "../../components/layout/FooterBar";
import Confirm from "../../components/layout/Confirm";

// database
import { createUser, getUser, updateUser } from "../../database/user";
import { LiffDemoUser } from "../../data/demo";

// utils
import { log, warn, debug, timeStart, timeEnd, error } from "../../utils/log";

const Version = process.env.REACT_APP_VERSION;
const LiffId = process.env.REACT_APP_LIFFID;
const IsDevelopment = process.env.NODE_ENV === "development";
const IsLogLoading = process.env.REACT_APP_LOG_LOADING === "true";
warn("Development Mode");
log(`KSR Website Version : ${Version}`);

export const RootContext = createContext(null);

export default function RootLayout() {
  const location = useLocation();

  const [lineUser, setLineUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const [isRequireLogin, setIsRequireLogin] = useState(false);
  const [cacheUsers, setCacheUsers] = useState([]);

  // check require login
  useEffect(() => {
    location.pathname === "/"
      ? setIsRequireLogin(false)
      : setIsRequireLogin(true);
  }, [location]);

  const fetchUserData = async () => {
    if (lineUser) {
      incLoading("fetchUser");
      const resGetUser = await getUser(lineUser.userId);
      decLoading("fetchUser");

      if (!resGetUser.ok) {
        return pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
      }

      if (!resGetUser.data) {
        incLoading("fetchUser");
        const resCreateUser = await createUser(lineUser.userId, {
          line: lineUser,
        });
        decLoading("fetchUser");

        if (!resCreateUser.ok) {
          return pushToast(
            `โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`,
            "danger"
          );
        } else {
          setIsLogin(true);
          setUserData(resCreateUser.data);
        }
      } else {
        incLoading("fetchUser");
        const resUpdateUser = await updateUser(lineUser.userId);
        decLoading("fetchUser");

        if (!resUpdateUser.ok) {
          return pushToast(
            `โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`,
            "danger"
          );
        } else {
          setIsLogin(true);
          setUserData({ ...resGetUser.data, ...resUpdateUser.data });
        }
      }
    }
  };

  const lineAutoLogin = async (force = false) => {
    const queryParams = new URLSearchParams(location.search);
    const liffClientId = queryParams.get("liffClientId") || null;

    if (force && IsDevelopment) {
      return setLineUser(LiffDemoUser);
    }

    if (!liff.isInClient() && !force) {
      if (!liffClientId) {
        return;
      }
    }

    incLoading("lineLogin");
    await liff.init({ liffId: LiffId, withLoginOnExternalBrowser: true });

    if (!liff.isLoggedIn()) {
      const destinationUrl = window.location.href;
      liff.login({ redirectUri: destinationUrl });
    }

    try {
      const profile = await liff.getProfile();
      const email = liff.getDecodedIDToken()?.email;
      const lineData = {
        ...profile,
        email,
      };

      setLineUser(lineData);
    } catch (err) {
      error(`error lineAutoLogin:`, err);
      pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
    }

    decLoading("lineLogin");
  };

  useEffect(() => {
    if (!IsDevelopment) {
      lineAutoLogin();
    }
  }, []);

  useEffect(() => {
    if (lineUser) {
      fetchUserData();
    }
  }, [lineUser]);

  // ======= Cache setting ========
  const getUserCache = async (uid) => {
    const isExitUser = cacheUsers.find((user) => user?.uid === uid);
    if (isExitUser) {
      return isExitUser;
    }
    incLoading("getUser");
    const resGetUser = await getUser(uid);
    decLoading("getUser");

    if (resGetUser.ok && resGetUser.data) {
      setCacheUsers((prev) => {
        return [...prev, resGetUser.data];
      });
      return resGetUser.data;
    }
  };

  // ======= Confirm setting ========
  const [confirm, setConfirm] = useState({
    title: "",
    body: "",
    btnLeft: "ยกเลิก",
    btnRight: "ตกลง",
    result: null,
    isOpen: false,
  });

  const closeConfirm = (result) => {
    log(`closeConfirm: ${result}`);
    setConfirm((prevConfirm) => ({
      ...prevConfirm,
      isOpen: false,
      result,
    }));
  };

  const openConfirm = async (
    title,
    body,
    btnLeft = "ยกเลิก",
    btnRight = "ตกลง"
  ) => {
    setConfirm((prevConfirm) => ({
      ...prevConfirm,
      title,
      body,
      btnLeft,
      btnRight,
      isOpen: true,
      result: null,
    }));

    return new Promise((resolve) => {
      const check = () => {
        setConfirm((prevConfirm) => {
          if (prevConfirm.result !== null) {
            resolve(prevConfirm.result);
          } else {
            setTimeout(check, 100);
          }
          return prevConfirm;
        });
      };
      check();
    });
  };

  // ======= Alert setting ========
  const [alert, setAlert] = useState({
    title: "",
    body: "",
    btnText: "ตกลง",
    onClick: () => {},
    isOpen: false,
  });

  const closeAlert = () => {
    setAlert({
      ...alert,
      isOpen: false,
    });
  };

  const openAlert = (
    title,
    body,
    btnText = "ตกลง",
    onClick = () => {},
    isError = false
  ) => {
    setAlert({
      title,
      body,
      btnText,
      onClick,
      isError,
      isOpen: true,
    });
  };

  // ======= Toasts setting ========
  const [toasts, setToasts] = useState([]);

  const pushToast = (
    text,
    type = "correct",
    time = 2000,
    icon = null,
    color = null
  ) => {
    setToasts([
      ...toasts,
      {
        text,
        type,
        time,
        icon,
        color,
      },
    ]);
  };

  // ======= Loading setting ========
  const [listLoading, setListLoading] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const incLoading = (key) => {
    setListLoading((prevListLoading) => {
      if (!prevListLoading.includes(key)) {
        timeStart(key);
        if (IsLogLoading) debug(`incLoading: ${key}`);
        return [...prevListLoading, key];
      }
      return [...prevListLoading];
    });
  };

  const decLoading = (key) => {
    setListLoading((prevListLoading) => {
      if (prevListLoading.includes(key)) {
        timeEnd(key);
        if (IsLogLoading) debug(`decLoading: ${key}`);
        return prevListLoading.filter((item) => item !== key);
      }
      return [...prevListLoading];
    });
  };

  const checkLoading = (...keys) => {
    if (keys.length === 0) {
      return listLoading.length > 0;
    } else {
      return keys.some((item) => listLoading.includes(item));
    }
  };

  const waitLoading = async (...keys) => {
    if (keys.length > 0) {
      await Promise.all(
        keys.map(
          (key) =>
            new Promise((resolve) => {
              const check = () => {
                if (!listLoading.includes(key)) {
                  resolve();
                } else {
                  setTimeout(check, 100);
                }
              };
              check();
            })
        )
      );
    } else {
      await new Promise((resolve) => {
        const check = () => {
          if (listLoading.length === 0) {
            resolve();
          } else {
            setTimeout(check, 100);
          }
        };
        check();
      });
    }
  };

  useEffect(() => {
    setIsLoading(listLoading.length > 0);
  }, [listLoading]);

  const states = {
    alert: { alert, openAlert, closeAlert },
    confirm: { confirm, openConfirm, closeConfirm },
    toast: { toasts, pushToast },
    cache: { cacheUsers, setCacheUsers, getUserCache },
    user: {
      lineUser,
      userData,
      isLogin,
      isRequireLogin,
      setIsRequireLogin,
    },
    loading: {
      isLoading,
      incLoading,
      decLoading,
      checkLoading,
      waitLoading,
      listLoading,
    },
  };

  return (
    <RootContext.Provider value={states}>
      <NavBar login={lineAutoLogin} />
      <LoadingScreen isLoading={isLoading} />
      <MultiToast />

      <div className=" h-[100vh-8rem] translate-y-16 ">
        {/* <div className=" lg:w-[414px] lg:mx-auto bg-white min-h-screen shadow-lg "> */}
        <Alert />
        <Confirm />
        <Outlet />
        {/* </div> */}
      </div>

      <FooterBar version={Version} />
    </RootContext.Provider>
  );
}
