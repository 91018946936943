import Bill from "../pages/bill/Bill";
import AddBill from "../pages/bill/AddBill";
import AllBill from "../pages/bill/AllBill";
import BillDashboard from "../pages/bill/BillDashboard";

const billRoutes = [
  {
    path: "",
    element: <Bill />,
  },
  {
    path: ":billId",
    element: <Bill />,
  },
  {
    path: "add",
    element: <AddBill />,
  },
  {
    path: "add/:bookingId",
    element: <AddBill />,
  },
  {
    path: "all",
    element: <AllBill />,
  },
  {
    path: "dashboard",
    element: <BillDashboard />,
  },
];

export default billRoutes;
