/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

// components
import Header from "../../components/header/Header";
import MainBox from "../../components/layout/MainBox";
import Table from "../../components/table/Table";
import LineAvatar from "../../components/LineAvatar";

// database
import { realtimeGetWorksByUid } from "../../database/work";
import { getUser } from "../../database/user";

// utils
import { RootContext } from "../app/Root";
import { areSameDate, convertTimestampToDate } from "../../utils/datetime";

const findMatchDateType = (works, date, type) => {
  for (const work of works) {
    const workdate = convertTimestampToDate(work.datetime);
    if (areSameDate(date, workdate) && work.type === type) {
      return work;
    }
  }

  return null;
};

const calculateWorkList = (works) => {
  let workResult = [];
  works.forEach((work) => {
    const workdate = convertTimestampToDate(work.datetime);
    const isInWorkResult = workResult.find((w) =>
      areSameDate(w.datetime, workdate)
    );
    if (!isInWorkResult) {
      workResult.push({
        datetime: workdate,
        in: findMatchDateType(works, workdate, "in"),
        out: findMatchDateType(works, workdate, "out"),
      });
    }
  });

  return workResult;
};

export default function AllWork() {
  let { userId } = useParams();
  const rootContext = useContext(RootContext);

  const [ownerUser, setOwnerUser] = useState(null);
  const [works, setWorks] = useState(null);
  const [displayWorks, setDisplayWorks] = useState([]);

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { isLogin, userData } = rootContext.user;
  const { incLoading, decLoading } = rootContext.loading;

  const getUserData = async (uid) => {
    incLoading("fetchUser");
    const resFindUser = await getUser(uid);
    decLoading("fetchUser");

    if (resFindUser.data) {
      return resFindUser.data;
    } else {
      if (!resFindUser.ok) {
        pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
        return null;
      }
      openAlert(
        "ไม่พบข้อมูลผู้ใช้",
        "ไม่พบข้อมูลผู้ใช้ #" + uid,
        "ตกลง",
        () => {},
        true
      );
      return null;
    }
  };

  const fetchPoints = async () => {
    let uid = userData.uid;

    if (userId) {
      const ownerUser = await getUserData(userId);
      if (ownerUser) {
        setOwnerUser(ownerUser);
        uid = userId;
      }
    }

    incLoading("realtimeGetWorks");
    realtimeGetWorksByUid(setWorks, uid, "all");
  };

  useEffect(() => {
    if (isLogin && userData?.type === "employee") {
      fetchPoints();
    }
  }, [userId, isLogin]);

  useEffect(() => {
    if (works) {
      decLoading("realtimeGetWorks");
      setDisplayWorks(calculateWorkList(works));
    }
  }, [works]);

  return (
    <>
      <Header
        title={`วันทำงาน ${Math.floor(works?.length / 2)} วัน`}
        requireType="employee"
        userType={userData?.type}
      />

      <div className="pt-36 pb-20">
        {ownerUser && ownerUser?.uid !== userData?.uid && (
          <MainBox>
            <div className=" flex justify-center p-4">
              <LineAvatar user={ownerUser} />
            </div>
          </MainBox>
        )}

        <MainBox>
          <Table
            data={displayWorks}
            config={[
              {
                head: "วันที่",
                type: "date",
                data: "in.datetime",
              },
              {
                head: "เข้างาน",
                type: "time",
                data: "in.datetime",
              },
              {
                head: "ออกงาน",
                type: "time",
                data: "out.datetime",
              },
            ]}
          />
        </MainBox>
      </div>
    </>
  );
}
