import AllBooking from "../pages/booking/AllBooking";
import Booking from "../pages/booking/Booking";
import BookingDetail from "../pages/booking/BookingDetail";

const bookingRoutes = [
  {
    path: "",
    element: <Booking />,
  },
  {
    path: "all",
    element: <AllBooking />,
  },
  {
    path: ":bookingId",
    element: <BookingDetail />,
  },
];

export default bookingRoutes;
