/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// database
import {
  addPoint,
  getPointsByUid,
  realtimeGetPoints,
} from "../../database/point";
import {
  addRedeem,
  countAllRedeem,
  getRedeemsByUid,
} from "../../database/redeem";

// components
import { FaStar, FaGift, FaPlusCircle, FaCarSide } from "react-icons/fa";
import Table from "../../components/table/Table";
import Header from "../../components/header/Header";
import MainBox from "../../components/layout/MainBox";

// utils
import { RootContext } from "../app/Root";
import { selfMessages } from "../../services/lineMessaging";

export default function AllPoint() {
  const rootContext = useContext(RootContext);
  const navigate = useNavigate();

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { openConfirm } = rootContext.confirm;
  const { isLogin, userData } = rootContext.user;
  const { incLoading, decLoading } = rootContext.loading;

  const [points, setPoints] = useState(null);
  const [displayPoints, setDisplayPoints] = useState([]);
  const [lenRedeem, setLenRedeem] = useState(0);

  // ------------- count redeem data ----------------
  const countRedeem = async () => {
    const resCountRedeem = await countAllRedeem("pending");
    if (resCountRedeem.ok) {
      setLenRedeem(resCountRedeem.data);
    }
  };

  // ------------- filter data by search ----------------
  function countPoints(points) {
    const result = [];
    points.forEach((item) => {
      const existingItem = result.find((r) => r.uid === item.uid);
      const point = item.point || 1;

      if (existingItem) {
        existingItem.point += point;
      } else {
        result.push({ ...item, point });
      }
    });
    return result;
  }

  // ------------- get all data ----------------
  useEffect(() => {
    if (isLogin && userData?.type === "employee") {
      incLoading("realtimeGetPoints");
      realtimeGetPoints(setPoints, "active");
      countRedeem();
    }
  }, [isLogin]);

  // ------------- combine data ----------------
  useEffect(() => {
    if (points) {
      decLoading("realtimeGetPoints");
      setDisplayPoints(countPoints(points));
    }
  }, [points]);

  // ------------- function for table options ----------------
  const handleUsePoint = async ({ uid: custUid }) => {
    const { uid: empUid } = userData;

    const resFindPoint = await getPointsByUid(custUid);
    if (!resFindPoint.ok) {
      return pushToast("โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!", "danger");
    }

    const custPoint = resFindPoint.data;
    if (custPoint.length < 10) {
      return pushToast("แต้มของคุณไม่พอ", "danger");
    }

    const resFindAllRedeem = await getRedeemsByUid(custUid, "pending");
    if (!resFindAllRedeem.ok) {
      return pushToast("โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!", "danger");
    }

    if (resFindAllRedeem.data?.length > 0) {
      return openAlert(
        "มีการแลกสิทธิ์รอการตรวจสอบ",
        "คุณมีการแลกสิทธิ์รอการตรวจสอบอยู่ โปรดรอการตรวจสอบก่อน",
        "ตกลง",
        () => {},
        true
      );
    }

    const isConfirm = await openConfirm(
      "ยืนยันการใช้แต้ม",
      "คุณต้องการใช้ 10 แต้มเพื่อแลกสิทธิ์ล้างสีรถฟรี ใช่หรือไม่?"
    );
    if (!isConfirm) {
      return;
    }

    const pointIds = custPoint
      .reverse()
      .slice(0, 10)
      .map((point) => point.id);
    const resAddRedeem = await addRedeem(
      custUid,
      empUid,
      pointIds,
      "byEmployee"
    );
    if (!resAddRedeem.ok) {
      return pushToast("ไม่สามารถแลกสิทธิ์ได้ โปรดลองใหม่อีกครั้ง", "danger");
    }

    if (isLogin) {
      selfMessages([
        {
          type: "text",
          text: `กำลังรอการยืนยันจากลูกค้า #${custUid} สำหรับการแลกสิทธิ์ล้างสีรถฟรี... (ด้วยคะแนน 10 แต้ม)`,
        },
      ]);
    }

    openAlert(
      "การใช้สิทธิ์ล้างสีรถฟรี",
      `กำลังรอการยืนยันจากลูกค้า #${custUid} สำหรับการแลกสิทธิ์ล้างสีรถฟรี... (ด้วยคะแนน 10 แต้ม)`,
      "ตกลง",
      () => {
        navigate("/point/redeem");
      },
      false
    );
  };

  const handleAddPoint = async ({ uid: custUid }) => {
    const resConfirm = await openConfirm(
      "เพิ่มแต้มสะสม",
      `คุณต้องการเพิ่มแต้ม 1 แต้มให้กับลูกค้า #${custUid} ใช่หรือไม่?`
    );
    if (!resConfirm) return;

    incLoading("addPoint");
    const resAddPoint = await addPoint(custUid);
    decLoading("addPoint");

    if (!resAddPoint.ok) {
      return pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
    } else {
      openAlert(
        "เพิ่มแต้มสำเร็จ",
        `เพิ่มแต้ม 1 แต้มให้กับลูกค้า #${custUid} เรียบร้อยแล้ว`
      );
    }
  };

  const handlePointDetail = ({ uid: custUid }) => {
    return navigate(`/point/${custUid}`);
  };

  const checkDisabledUsepoint = ({ point }) => {
    return point < 10;
  };

  return (
    <div className=" min-h-screen">
      <Header
        title="ระบบจัดการแต้ม"
        requireType="employee"
        userType={userData?.type}
      />

      <div className="pt-36 pb-20">
        <MainBox>
          <Table
            pathAdd="/point/add"
            data={displayPoints}
            config={[
              {
                head: "ลูกค้า",
                type: "uidAvatar",
                data: "uid",
                align: "center",
              },
              {
                head: "แต้มรวม",
                type: "text",
                data: "point",
                sortAble: true,
              },
            ]}
            option={{
              head: {
                type: "uidAvatar",
                data: "uid",
                align: "center",
              },
              list: [
                {
                  icon: FaStar,
                  text: "ดูรายการคะแนน",
                  onClick: handlePointDetail,
                },
                {
                  icon: FaPlusCircle,
                  text: "เพิ่มแต้มสะสม 1 แต้ม",
                  onClick: handleAddPoint,
                },
                {
                  icon: FaCarSide,
                  text: "แลกใช้สิทธิ์ 10 แต้ม",
                  onClick: handleUsePoint,
                  onDisabled: checkDisabledUsepoint,
                },
              ],
            }}
          />
        </MainBox>

        <MainBox>
          <Link
            to="/point/redeem"
            className="relative inline-flex items-center w-full px-5 py-2.5 text-sm font-medium text-center text-black bg-gray-50 rounded-lg hover:bg-gray-100 ">
            <FaGift className="w-4 h-4 me-2" />
            รายการแลกแต้ม
            {lenRedeem > 0 && (
              <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                {lenRedeem}
              </div>
            )}
          </Link>
        </MainBox>
      </div>
    </div>
  );
}
