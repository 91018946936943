import {
  wh,
  wheres,
  addDocument,
  updateDocument,
  getDocumentById,
  getAllDocumentByQuery,
  realtimeGetAllDocumentByQuery,
} from "./database";
import { Timestamp, orderBy } from "firebase/firestore";

// --------- get realtime users ---------
export const realtimeGetUsers = (setUsers, type = "all", status = "all") => {
  let queries = [
    ...wheres(wh("type", "==", type), wh("status", "==", status)),
    orderBy("lastLogin", "desc"),
  ];
  return realtimeGetAllDocumentByQuery(setUsers, "user", queries);
};

// --------- get users ---------
export const getUser = async (id) => {
  return await getDocumentById("user", id);
};

export const getUsers = async (type = "all", status = "all") => {
  let queries = [
    ...wheres(wh("type", "==", type), wh("status", "==", status)),
    orderBy("lastLogin", "desc"),
  ];
  return await getAllDocumentByQuery("user", queries);
};

// ======= Update User Data =======
export const updateUser = async (id, userData = {}) => {
  delete userData.id;
  delete userData.lastLogin;
  delete userData.firstLogin;

  const newUserData = {
    ...userData,
    lastLogin: Timestamp.fromDate(new Date()),
  };

  return await updateDocument("user", id, newUserData);
};

// ======= Create User =======
export const createUser = async (
  id,
  userData,
  date = new Date(),
  intent = "firstWebLogin",
  status = "active",
  type = "customer"
) => {
  const docData = {
    ...userData,
    id,
    intent,
    type,
    status,
    lastLogin: Timestamp.fromDate(date),
    firstLogin: Timestamp.fromDate(date),
  };

  return await addDocument("user", docData);
};
