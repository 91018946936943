import { GoDotFill } from "react-icons/go";
import { FaGift } from "react-icons/fa6";

export default function ServiceDetail({
  mainService,
  additionalService,
  carType,
  promotion,
  totalPrice,
}) {
  return (
    <div className=" px-4 mb-5 bg-white">
      {mainService && (
        <>
          <div className="flex items-center mb-2 mt-4">
            <div className="flex-1 min-w-0 ">
              <h2 className="text-base font-extrabold tracking-tight text-gray-900">
                {mainService.name.th}
              </h2>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-gray-900">
              {mainService.price[carType]} บาท
            </div>
          </div>
          <ul className="space-y-2 text-gray-500 text-sm ">
            {mainService.services.map((item, idx) => (
              <li key={idx} className="flex space-x-2 items-center">
                <GoDotFill className="flex-shrink-0 w-3.5 h-3.5 text-red-600" />
                <span className="leading-tight">{item}</span>
              </li>
            ))}
          </ul>
        </>
      )}

      {additionalService.length > 0 && (
        <>
          <h2 className="mb-1 mt-4 text-base font-extrabold tracking-tight text-gray-900 dark:text-white">
            รายการบริการอื่นๆ{" "}
          </h2>
          <div className="flow-root">
            <ul className="">
              {additionalService.map((item, idx) => (
                <li key={idx} className="py-1  flex items-center">
                  <div className="flex-1 min-w-0">
                    <h2 className="text-sm leading-tight tracking-tight text-gray-500 flex space-x-2 items-center">
                      <GoDotFill className="flex-shrink-0 w-3.5 h-3.5 text-red-600" />
                      <span className="leading-tight">{item?.name.th}</span>
                    </h2>
                  </div>
                  <div className="inline-flex items-center text-sm font-semibold text-gray-900">
                    {item?.price[carType]} บาท
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      {promotion.id !== "none" && (
        <div className="max-w-sm p-6 bg-amber-400  mt-2 hover:scale-105  rounded-lg shadow ">
          <div className="flex space-x-2 items-center">
            <FaGift className="w-5 h-5 mb-3" />
            <h5 className="mb-2 text-lg font-semibold tracking-tight text-gray-900">
              โปรโมชั่น
            </h5>
          </div>
          <p className=" text-xs font-normal text-gray-900">
            {promotion.name.th}
          </p>
        </div>
      )}

      {/* Total Price */}
      <div className="mt-4">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-extrabold tracking-tight text-gray-900">
            ราคารวม
          </h2>
          <div className="inline-flex items-center text-lg font-semibold text-gray-900">
            {totalPrice.total} บาท
          </div>
        </div>

        {totalPrice.discount < 0 && (
          <>
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-extrabold tracking-tight text-gray-900">
                ส่วนลด
              </h2>
              <div className="inline-flex items-center text-lg font-semibold text-gray-900">
                {totalPrice.discount} บาท
              </div>
            </div>

            <div className="flex items-center justify-between">
              <h2 className="text-lg font-extrabold tracking-tight text-gray-900">
                ราคาสุทธิ
              </h2>
              <div className="inline-flex items-center text-lg font-semibold text-gray-900">
                {totalPrice.net} บาท
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
