import {
  wh,
  wheres,
  addDocument,
  updateDocument,
  getAllDocumentByQuery,
  realtimeGetAllDocumentByQuery,
  countDocumentByQuery,
} from "./database";
import { orderBy, Timestamp } from "firebase/firestore";

// --------- get realtime points ---------
export const realtimeGetRedeems = (setRedeems, status = "all") => {
  let queries = [
    ...wheres(wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return realtimeGetAllDocumentByQuery(setRedeems, "redeem", queries);
};

// --------- get redeems ---------
export const getRedeems = async () => {
  let queries = [orderBy("datetime", "desc")];
  return await getAllDocumentByQuery("redeem", queries);
};

export const getRedeemsByUid = async (uid, status = "pending") => {
  let queries = [
    ...wheres(wh("uid", "==", uid), wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return await getAllDocumentByQuery("redeem", queries);
};

// --------- add & update redeem ---------
export const addRedeem = async (
  uid,
  actor,
  pointIds,
  redeemType,
  status = "pending",
  date = new Date()
) => {
  const redeemData = {
    datetime: Timestamp.fromDate(date),
    status,
    uid,
    pointIds,
    actor,
    redeemType,
  };

  return await addDocument("redeem", redeemData);
};

export const updateRedeem = async (redeemId, redeemData = {}) => {
  return await updateDocument("redeem", redeemId, redeemData);
};

// --------- count redeem ---------
export const countAllRedeem = async (status) => {
  let queries = [
    ...wheres(wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return await countDocumentByQuery("redeem", queries);
};
