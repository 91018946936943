const userNavList = [
  {
    href: "/",
    name: "โปรโมชั่น",
  },
  {
    href: "/booking",
    name: "จองคิวออนไลน์",
  },
];

const customerNavList = [
  {
    href: "/",
    name: "โปรโมชั่น",
  },
  {
    href: "/point",
    name: "บัตรสะสมแต้ม",
  },
  {
    href: "/booking",
    name: "จองคิวออนไลน์",
  },
];

const employeeNavList = [
  {
    href: "/point/all",
    name: "รายการสะสมแต้ม",
  },
  {
    href: "/work/all",
    name: "รายการทำงาน",
  },
  {
    href: "/booking/all",
    name: "รายการจองคิว",
  },
  {
    href: "/bill/all",
    name: "รายการบิล",
  },
];

const managerNavList = [
  {
    href: "/admin/manager",
    name: "แดชบอร์ดผู้จัดการ",
  },
  {
    href: "/admin/manager/manage-user",
    name: "รายการผู้ใช้งาน",
  },
];

export { userNavList, customerNavList, employeeNavList, managerNavList };
