import Map from "../pages/shortly/Map";

const shortlyRoutes = [
  {
    path: "map",
    element: <Map />,
  },
];

export default shortlyRoutes;
