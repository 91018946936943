import { Fragment, forwardRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FaCheck, FaChevronDown } from "react-icons/fa6";
import { getData } from "../../utils/common";

const CustomInputSelection = forwardRef((props, ref) => {
  const {
    list,
    isOptional,
    keySpan,
    KeyDisabled,
    property = "name.th",
  } = props;
  const [selected, setSelected] = props.selected;

  const compareDepartments = (a, b) => {
    return (
      getData(a, property)?.toLowerCase() ===
      getData(b, property)?.toLowerCase()
    );
  };

  return (
    <Listbox by={compareDepartments} value={selected} onChange={setSelected}>
      <div className="relative mt-1">
        <Listbox.Button
          ref={ref}
          className="relative w-full cursor-default rounded py-3 pl-3 pr-10 text-left shadow focus:outline-none sm:text-sm bg-gray-100 border-0 text-gray-900  focus:ring-0 focus:border-0 ">
          <p
            className={`block truncate ${
              isOptional && compareDepartments(list[0], selected)
                ? "text-gray-400"
                : ""
            }`}>
            {getData(selected, property)}
          </p>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <FaChevronDown
              className="h-4 w-4 text-gray-300"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Listbox.Options className="absolute mt-1 z-30 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {list.map((item, idx) => (
              <Listbox.Option
                disabled={
                  KeyDisabled &&
                  !item.pids?.includes(KeyDisabled) &&
                  item.id !== "none"
                }
                key={idx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active
                      ? "bg-gray-100 text-gray-900"
                      : (isOptional && idx === 0) ||
                        (KeyDisabled &&
                          !item.pids?.includes(KeyDisabled) &&
                          item.id !== "none")
                      ? "text-gray-400"
                      : "text-gray-900"
                  }`
                }
                value={item}>
                {({ selected }) => (
                  <>
                    <p
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}>
                      {getData(item, property)}

                      {keySpan && item.price && (
                        <span className="text-gray-300 ml-2 text-xs">
                          {item.price[keySpan]}฿
                        </span>
                      )}

                      {item.describe && (
                        <span className="text-gray-300 ml-2 text-xs">
                          {item.describe}
                        </span>
                      )}
                    </p>

                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                        <FaCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
});

export default CustomInputSelection;
