import React from "react";

// icons
import {
  FaMapMarkerAlt,
  FaLine,
  FaPhone,
  FaFacebook,
  FaTiktok,
} from "react-icons/fa";
import { MdOutlineWeb } from "react-icons/md";

const footers = [
  {
    title: "Tiktok",
    href: "https://tiktok.com/@ksrcarcare",
    icon: FaTiktok,
  },
  {
    title: "Facebook",
    href: "https://facebook.com/ksrspacarclean",
    icon: FaFacebook,
  },
  {
    title: "Website",
    href: "https://ksrcarclean.com",
    icon: MdOutlineWeb,
  },
  {
    title: "Line OA",
    href: "https://lin.ee/QuxcRzj",
    icon: FaLine,
  },
  {
    title: "Phone",
    href: "tel:0654068840",
    icon: FaPhone,
  },
  {
    title: "Google Map",
    href: "https://maps.app.goo.gl/wkCvCMn9MqZSGgc29",
    icon: FaMapMarkerAlt,
  },
];

export default function FooterBar({ version }) {
  return (
    <footer className="px-4 bg-maindark relative ">
      <div className="w-full mx-auto max-w-screen-xl pb-2 pt-1 ">
        <ul className="grid grid-cols-3 lg:grid-cols-6 gap-2 items-center mt-3 text-sm font-medium text-gray-300">
          {footers.map((item, idx) => (
            <li key={idx} className={` flex justify-start items-center my-2 `}>
              <item.icon className="w-5 h-5 mr-1.5" />
              <a
                href={item.href}
                className="hover:underline me-4 whitespace-nowrap">
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <hr className="mb-4 border border-white" />
      <p className="text-white  pb-4 text-center whitespace-nowrap text-xs">
        เปิดบริการทุกวัน 8:30 - 18:30 น. ลานจอดรถชั้นล่างห้างโลตัสสงขลา
      </p>
      <p className=" absolute text-[10px] text-gray-300 top-1 z-20 right-1 ">
        version: {version}
      </p>
    </footer>
  );
}
