/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
const defaultAvatar =
  "https://firebasestorage.googleapis.com/v0/b/ksr-car-clean.appspot.com/o/static%2Fprofile.webp?alt=media&token=9bc8afdb-b3a1-4886-a9be-851e5d601da9";

export default function BookingInfo({
  userData,
  customer,
  handleCancel,
  status,
  datetime,
  bookingDatetime,
  note = "",
}) {
  const [percentMin, setPercentMin] = useState(0);
  const [isExpired, setIsExpired] = useState(false);

  const calPercentMin = () => {
    const now = new Date();
    const fullTime = bookingDatetime.getTime() - datetime.getTime();
    const nowTime = now.getTime() - datetime.getTime();
    const result = (nowTime / fullTime) * 100;

    if (result.toFixed(2) > 100) {
      setIsExpired(true);
    }

    setPercentMin(result.toFixed(2) > 100 ? 100 : result.toFixed(2));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isExpired) {
        calPercentMin();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [datetime, bookingDatetime]);

  return (
    <div className="relative">
      <div className="flex flex-col items-center pb-10 mt-5 ">
        <img
          className="w-24 h-24 mb-3 rounded-full shadow-lg"
          src={userData?.line?.pictureUrl ?? defaultAvatar}
          onError={(e) => {
            e.target.src = defaultAvatar;
          }}
          lazy="true"
          alt=""
        />
        <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
          {customer?.name || userData?.line?.displayName || "ไม่ระบุชื่อ"}
        </h5>
        <span className="text-sm text-gray-500">{customer?.phone}</span>
        <span
          className={` text-xs ${
            status === "created"
              ? " text-gray-500"
              : status === "canceled"
              ? " text-red-500"
              : isExpired
              ? " text-amber-500"
              : " text-green-500"
          }`}>
          สถานะ:
          {status === "created"
            ? " กำลังลังรอการยืนยันจากร้านค้า"
            : status === "canceled"
            ? " ยกเลิกการจองคิวแล้ว"
            : isExpired
            ? " เลยเวลาที่จองไว้แล้ว"
            : ` ${status}`}
        </span>
        {note !== "" && (
          <span className="text-xs text-gray-400">หมายเหตุ: {note}</span>
        )}
      </div>

      <div className="flex justify-between mb-1 mx-2">
        <span
          className={`text-sm font-medium  ${
            isExpired ? "text-amber-600" : "text-red-700"
          }`}>
          {isExpired
            ? "เลยเวลาที่จองไว้แล้ว"
            : `จองคิว${bookingDatetime.toLocaleDateString("th-TH", {
                weekday: "long",
              })} ที่ ${bookingDatetime.toLocaleDateString(
                "th-TH"
              )} เวลา ${bookingDatetime.toLocaleTimeString("th-TH", {
                hour: "2-digit",
                minute: "2-digit",
              })}`}
        </span>
      </div>
      <div className="w-full bg-gray-200  h-2.5">
        <div
          className={`0 h-2.5 ${isExpired ? "bg-amber-600" : "bg-red-600"} `}
          style={{ width: `${percentMin}%` }}></div>
      </div>
    </div>
  );
}
