const services = [
  { name: "แพ็คเกจล้างสีมอไซค์พรีเมี่ยม", price: [120, 150, 220] },
  {
    name: "แพ็คเกจล้างสีมอไซค์ขั้นสูง",
    price: [170, 200, 270],
  },
  {
    name: "แพ็คเกจขัดคราบมอไซค์ยางมะตอย",
    price: [250, 280, 350],
  },
  {
    name: "แพ็คเกจทำความสะอาดโซ่มอไซค์",
    price: [320, 350, 420],
  },
  {
    name: "แพ็คเกจเคลือบแก้วมอไซค์พรีเมี่ยม",
    price: [3590, 3990, 4590],
  },
  {
    name: "แพ็คเกจเคลือบแก้วมอไซค์หรูหรา",
    price: [4990, 5490, 5990],
  },
  {
    name: "แพ็คเกจล้างสีรถยนต์พรีเมี่ยม",
    price: [550, 590, 620, 690],
  },
  {
    name: "แพ็คเกจล้างสีรถยนต์ขั้นสูง",
    price: [750, 790, 820, 890],
  },
  {
    name: "แพ็คเกจขัดคราบยางมะตอยรถยนต์",
    price: [950, 990, 1020, 1090],
  },
  {
    name: "แพ็คเกจขัดคราบไฟหน้ารถยนต์",
    price: [1250, 1290, 1320, 1390],
  },

  {
    name: "แพ็คเกจฟอกเบาะหนังรถยนต์",
    price: [3290, 3590, 4290, 4590],
  },
  {
    name: "แพ็คเกจฟอกเบาะพรมรถยนต์",
    price: [4290, 4590, 5590, 5990],
  },
  {
    name: "แพ็คเกจขัดชักเงา",
    price: [1490, 1590, 1790, 1990],
  },
  {
    name: "แพ็คเกจขัดลบรอยรถยนต์",
    price: [2250, 2390, 2650, 2990],
  },
  {
    name: "แพ็คเกจขัดลบรอยรถยนต์ขั้นสูง",
    price: [3550, 3790, 4150, 4490],
  },
  {
    name: "แพ็คเกจเคลือบแก้วรถยนต์พรีเมี่ยม",
    price: [17990, 19490, 23990, 27490],
  },
  {
    name: "แพ็คเกจเคลือบแก้วรถยนต์หรูหรา",
    price: [22490, 24990, 29490, 32990],
  },
  {
    name: "ล้างสีภายนอกและพ่นล้อดำรถยนต์",
    price: [350, 390, 420, 490],
  },
  {
    name: "ดูดฝุ่นและแว๊กภายในรถยนต์",
    price: [350, 390, 420, 490],
  },
  {
    name: "อบโอโซนฆ่าเชื้อดับกลิ่น",
    price: [100, 100, 100, 100],
  },
  {
    name: "เคลือบกระจกกันฝน",
    price: [100, 100, 100, 100],
  },
  {
    name: "เปลี่ยนใบปัดน้ำฝน",
    price: [390, 390, 390, 390],
  },
  {
    name: "เปลี่ยนน้ำยาล้างกระจก",
    price: [100, 100, 100, 100],
  },
  {
    name: "เคลือบเงาปกป้องสีรถ",
    price: [100, 100, 100, 100],
  },
  {
    name: "ล้างพ่นเคลือบเงาห้องเครื่อง",
    price: [500, 500, 500, 500],
  },
  {
    name: "ขัดคราบยางมะตอย",
    price: [700, 700, 700, 700],
  },
  {
    name: "ขัดและเคลือบไฟหน้า",
    price: [1200, 1200, 1200, 1200],
  },
  {
    name: "ขัดลบรอยสีรถทั้งคัน",
    price: [2190, 2290, 2490, 2690],
  },
  {
    name: "ฟอกเบาะหนัง 1 ที่นั่ง",
    price: [1090, 1090, 1090, 1090],
  },
  {
    name: "ฟอกเบาะพรม 1 ที่นั่ง",
    price: [1390, 1390, 1390, 1390],
  },
  {
    name: "ฟอกพรมพื้นรถ",
    price: [1590, 1690, 1990, 2090],
  },
  {
    name: "ฟอกพรมเพดานรถ",
    price: [790, 890, 990, 1090],
  },
  {
    name: "ขัดลบรอยเฉพาะจุด",
    price: [350, 350, 350, 350],
  },
];

const mcMainPackageServices = [
  {
    id: "p2",
    name: {
      th: "รายการล้างสีมอเตอร์ไซค์",
      en: "Motorcycle Washing",
    },
    vehicles: ["motorbike", "minibike", "bigbike"],
    packages: [
      {
        id: "mcw1",
        pid: "p2",
        name: {
          th: "แพ็คเกจล้างสีพรีเมี่ยม",
          en: "Premium Carwash Package",
        },
        services: ["ล้างสีภายนอกและพ่นล้อดำ"],
        price: {
          motorbike: 120,
          minibike: 150,
          bigbike: 220,
        },
        time: 30,
      },
      {
        id: "mcw2",
        pid: "p2",
        name: {
          th: "แพ็คเกจล้างสีขั้นสูง",
          en: "Advanced Carwash Package",
        },
        services: ["ล้างสีภายนอกและพ่นล้อดำ", "เคลือบเงาปกป้องสีรถ"],
        price: {
          motorbike: 170,
          minibike: 200,
          bigbike: 270,
        },
        time: 60,
      },
      {
        id: "mcw3",
        pid: "p2",
        name: {
          th: "แพ็คเกจขัดคราบยางมะตอย",
          en: "Asphalt Stain Removing Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "เคลือบเงาปกป้องสีรถ",
          "ขัดคราบยางมะตอย",
        ],
        price: {
          motorbike: 250,
          minibike: 280,
          bigbike: 350,
        },
        time: 90,
      },
      {
        id: "mcw4",
        pid: "p2",
        name: {
          th: "แพ็คเกจทำความสะอาดโซ่",
          en: "Chain Cleaning Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "เคลือบเงาปกป้องสีรถ",
          "ล้างและหล่อลื่นโซ่",
        ],
        price: {
          motorbike: 320,
          minibike: 350,
          bigbike: 420,
        },
        time: 360,
      },
    ],
  },
  {
    id: "p4",
    name: {
      th: "รายการเคลือบแก้วมอเตอร์ไซค์",
      en: "Motorcycle Coating",
    },
    vehicles: ["motorbike", "minibike", "bigbike"],
    packages: [
      {
        id: "mcc1",
        pid: "p4",
        name: {
          th: "แพ็คเกจเคลือบแก้วพรีเมี่ยม",
          en: "Premium Glass Coating Package",
        },
        describe: "ด้วยน้ำยา Black Sapphile 10H Graphine Ceramic แท้",
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ขัดคราบยางมะตอย",
          "เคลือบแก้วหนา 30 ไมครอน",
          "ประกันคุณภาพน้ำยา 3 ปี",
          "เคลือบหมวกกันน็อค 1 ใบ",
        ],
        price: {
          motorbike: 3590,
          minibike: 3990,
          bigbike: 4590,
        }, // pro -1000 3first/month
        time: 480,
      },
      {
        id: "mcc2",
        pid: "p4",
        name: {
          th: "แพ็คเกจเคลือบแก้วหรูหรา",
          en: "Luxury Glass Coating Package",
        },
        describe: "ด้วยน้ำยา Black Sapphile 10H Graphine Ceramic แท้",
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ขัดคราบยางมะตอย",
          "เคลือบแก้วหนา 50 ไมครอน",
          "ประกันคุณภาพน้ำยา 5 ปี",
          "เคลือบหมวกกันน็อค 2 ใบ",
          "ทรีตเม้นท์ฟรีทุก 6 เดือน (รวม 6 ครั้ง)",
        ],
        price: {
          motorbike: 4990,
          minibike: 5490,
          bigbike: 5990,
        }, // pro -1000 3first/month
        time: 480,
      },
    ],
  },
];

const mainPackageServices = [
  {
    id: "p1",
    vehicles: ["sedan", "pickup", "suv", "mpv"],
    name: {
      th: "รายการล้างสีรถยนต์",
      en: "Car Washing",
    },
    packages: [
      {
        id: "cw1",
        pid: "p1",
        name: {
          th: "แพ็คเกจล้างสีพรีเมี่ยม",
          en: "Premium Carwash Package",
        },
        services: ["ล้างสีภายนอกและพ่นล้อดำ", "ดูดฝุ่นและแว๊กภายใน"],
        price: {
          sedan: 550,
          pickup: 590,
          suv: 620,
          mpv: 690,
        },
        time: 60,
      },
      {
        id: "cw2",
        pid: "p1",
        name: {
          th: "แพ็คเกจล้างสีขั้นสูง",
          en: "Advanced Carwash Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "เคลือบเงาปกป้องสีรถ",
          "ล้างพ่นเคลือบเงาห้องเครื่อง",
        ],
        price: {
          sedan: 750,
          pickup: 790,
          suv: 820,
          mpv: 890,
        },
        time: 90,
      },
      {
        id: "cw3",
        pid: "p1",
        name: {
          th: "แพ็คเกจขัดคราบยางมะตอย",
          en: "Asphalt Stain Removing Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "เคลือบเงาปกป้องสีรถ",
          "ขัดคราบยางมะตอย",
        ],
        price: {
          sedan: 950,
          pickup: 990,
          suv: 1020,
          mpv: 1090,
        },
        time: 90,
      },
      {
        id: "cw4",
        pid: "p1",
        name: {
          th: "แพ็คเกจขัดคราบไฟหน้า",
          en: "Headlight Stain Removing Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "เคลือบเงาปกป้องสีรถ",
          "ขัดและเคลือบไฟหน้า",
        ],
        price: {
          sedan: 1250,
          pickup: 1290,
          suv: 1320,
          mpv: 1390,
        },
        time: 120,
      },
    ],
  },
  {
    id: "p5",
    vehicles: ["sedan", "pickup", "suv", "mpv"],
    name: {
      th: "รายการฟอกเบาะรถยนต์",
      en: "Car Seat Cleaning",
    },
    packages: [
      {
        id: "sc1",
        pid: "p5",
        name: {
          th: "แพ็คเกจฟอกเบาะหนัง",
          en: "Leather Seat Cleaning Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "ฟอกเบาะหนังทุกที่นั่ง",
          "ฟอกพรมพื้นรถ",
        ],
        price: {
          sedan: 3290,
          pickup: 3590,
          suv: 4290,
          mpv: 4590,
        }, // pro -1000 only this month
        time: 480,
      },
      {
        id: "sc2",
        pid: "p5",
        name: {
          th: "แพ็คเกจฟอกเบาะพรม",
          en: "Carpet Seat Cleaning Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "ฟอกเบาะพรมทุกที่นั่ง",
          "ฟอกพรมพื้นรถ",
        ],
        price: {
          sedan: 4290,
          pickup: 4590,
          suv: 5590,
          mpv: 5990,
        }, // pro -1000 only this month,
        time: 1140,
      },
    ],
  },
  {
    id: "p7",
    vehicles: ["sedan", "pickup", "suv", "mpv"],
    name: {
      th: "รายการขัดสีลบรอยรถยนต์",
      en: "Car Wetlook",
    },
    packages: [
      {
        id: "wl1",
        pid: "p7",
        name: {
          th: "แพ็คเกจขัดชักเงา",
          en: "Polishing Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "เคลือบเงาปกป้องสีรถ",
          "ขัดชักเงาสีรถ",
        ],
        price: {
          sedan: 1490,
          pickup: 1590,
          suv: 1790,
          mpv: 1990,
        },
        time: 180,
      },
      {
        id: "wl2",
        pid: "p7",
        name: {
          th: "แพ็คเกจขัดลบรอยสีรถ",
          en: "Wetlook Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "เคลือบเงาปกป้องสีรถ",
          "ขัดลบรอยสีรถทั้งคัน",
          "ล้างพ่นเคลือบเงาห้องเครื่อง",
        ],
        price: {
          sedan: 2250,
          pickup: 2390,
          suv: 2650,
          mpv: 2990,
        },
        time: 240,
      },
      {
        id: "wl3",
        pid: "p7",
        name: {
          th: "แพ็คเกจขัดลบรอยขั้นสูง",
          en: "Advanced Wetlook Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "เคลือบเงาปกป้องสีรถ",
          "ขัดลบรอยสีรถทั้งคัน",
          "ล้างพ่นเคลือบเงาห้องเครื่อง",
        ],
        price: {
          sedan: 3550,
          pickup: 3790,
          suv: 4150,
          mpv: 4490,
        },
        time: 240,
      },
    ],
  },
  {
    id: "p3",
    vehicles: ["sedan", "pickup", "suv", "mpv"],
    name: {
      th: "รายการเคลือบแก้วรถยนต์",
      en: "Car Coating",
    },
    packages: [
      {
        id: "cc1",
        pid: "p3",
        name: {
          th: "แพ็คเกจเคลือบแก้วพรีเมี่ยม",
          en: "Premium Glass Coating Package",
        },
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "ขัดลบรอยสีรถทั้งคัน",
          "ขัดคราบยางมะตอย",
          "เคลือบแก้วหนา 30 ไมครอน",
          "ประกันคุณภาพน้ำยา 3 ปี",
        ],
        describe: "ด้วยน้ำยา Black Sapphile 10H Graphine Ceramic แท้",
        price: {
          sedan: 17990,
          pickup: 19490,
          suv: 23990,
          mpv: 27490,
        }, // pro -10000 3first/month
        time: 480,
      },
      {
        id: "cc2",
        pid: "p3",
        name: {
          th: "แพ็คเกจเคลือบแก้วหรูหรา",
          en: "Luxury Glass Coating Package",
        },
        describe: "ด้วยน้ำยา Black Sapphile 10H Graphine Ceramic แท้",
        services: [
          "ล้างสีภายนอกและพ่นล้อดำ",
          "ดูดฝุ่นและแว๊กภายใน",
          "ขัดลบรอยสีรถทั้งคัน",
          "ขัดคราบยางมะตอย",
          "เคลือบแก้วหนา 50 ไมครอน",
          "ประกันคุณภาพน้ำยา 5 ปี",
          "ทรีตเม้นท์ฟรี 6 ครั้ง (ทุก 6 เดือน)",
        ],
        price: {
          sedan: 22490,
          pickup: 24990,
          suv: 29490,
          mpv: 32990,
        }, // pro -10000 3first/month
        time: 480,
      },
    ],
  },
];

const additionalServices = [
  {
    id: "as1",
    name: {
      th: "ล้างสีภายนอกและพ่นล้อดำ",
      en: "Washing and Tire Coating",
    },
    price: {
      sedan: 350,
      pickup: 390,
      suv: 420,
      mpv: 490,
    },
    time: 60,
  },
  {
    id: "as2",
    name: {
      th: "ดูดฝุ่นและแว๊กภายใน",
      en: "Vacuum and Interior Cleaning",
    },
    price: {
      sedan: 350,
      pickup: 390,
      suv: 420,
      mpv: 490,
    },
    time: 60,
  },
  {
    id: "as3",
    name: {
      th: "อบโอโซนฆ่าเชื้อดับกลิ่น",
      en: "Ozone Sterilization",
    },
    price: {
      sedan: 100,
      pickup: 100,
      suv: 100,
      mpv: 100,
    },
    time: 10,
  },
  {
    id: "as4",
    name: {
      th: "เคลือบกระจกกันฝน",
      en: "Rain Repellent Coating",
    },
    price: {
      sedan: 100,
      pickup: 100,
      suv: 100,
      mpv: 100,
    },
    time: 10,
  },
  {
    id: "as5",
    name: {
      th: "เปลี่ยนใบปัดน้ำฝน",
      en: "Wiper Blade Replacement",
    },
    price: {
      sedan: 390,
      pickup: 390,
      suv: 390,
      mpv: 390,
    },
    time: 10,
  },
  {
    id: "as6",
    name: {
      th: "เปลี่ยนน้ำยาล้างกระจก",
      en: "Windshield Washer Fluid Replacement",
    },
    price: {
      sedan: 100,
      pickup: 100,
      suv: 100,
      mpv: 100,
    },
    time: 10,
  },
  {
    id: "as7",
    name: {
      th: "เคลือบเงาปกป้องสีรถ",
      en: "Waxing",
    },
    price: {
      sedan: 100,
      pickup: 100,
      suv: 100,
      mpv: 100,
    },
    time: 30,
  },
  {
    id: "as8",
    name: {
      th: "ล้างพ่นเคลือบเงาห้องเครื่อง",
      en: "Engine Room Coating",
    },
    price: {
      sedan: 500,
      pickup: 500,
      suv: 500,
      mpv: 500,
    },
    time: 30,
  },
  {
    id: "as9",
    name: {
      th: "ขัดคราบยางมะตอย",
      en: "Asphalt Stain Removing",
    },
    price: {
      sedan: 700,
      pickup: 700,
      suv: 700,
      mpv: 700,
    },
    time: 60,
  },
  {
    id: "as10",
    name: {
      th: "ขัดและเคลือบไฟหน้า",
      en: "Headlight Stain Removing",
    },
    price: {
      sedan: 1200,
      pickup: 1200,
      suv: 1200,
      mpv: 1200,
    },
    time: 90,
  },
  {
    id: "as11",
    name: {
      th: "ขัดลบรอยสีรถทั้งคัน",
      en: "Wetlook",
    },
    price: {
      sedan: 2190,
      pickup: 2290,
      suv: 2490,
      mpv: 2690,
    },
    time: 120,
  },
  {
    id: "as12",
    name: {
      th: "ฟอกเบาะหนัง 1 ที่นั่ง",
      en: "Leather Seat Cleaning",
    },
    price: {
      sedan: 1090,
      pickup: 1090,
      suv: 1090,
      mpv: 1090,
    },
    time: 180,
  },
  {
    id: "as13",
    name: {
      th: "ฟอกเบาะพรม 1 ที่นั่ง",
      en: "Carpet Seat Cleaning",
    },
    price: {
      sedan: 1390,
      pickup: 1390,
      suv: 1390,
      mpv: 1390,
    },
    time: 1440,
  },
  {
    id: "as14",
    name: {
      th: "ฟอกพรมพื้นรถ",
      en: "Carpet Cleaning",
    },
    price: {
      sedan: 1590,
      pickup: 1690,
      suv: 1990,
      mpv: 2090,
    },
    time: 480,
  },
  {
    id: "as15",
    name: {
      th: "ฟอกพรมเพดานรถ",
      en: "Ceiling Cleaning",
    },
    price: {
      sedan: 790,
      pickup: 890,
      suv: 990,
      mpv: 1090,
    },
    time: 480,
  },
  {
    id: "as16",
    name: {
      th: "ขัดลบรอยเฉพาะจุด",
      en: "Spot Wetlook",
    },
    price: {
      sedan: 350,
      pickup: 350,
      suv: 350,
      mpv: 350,
    },
    time: 120,
  },
];

const mcAdditionalServices = [
  {
    id: "mcas1",
    name: {
      th: "เคลือบเงาปกป้องสีรถ",
      en: "Waxing",
    },
    price: {
      motorbike: 50,
      minibike: 50,
      bigbike: 50,
    },
    time: 10,
  },
  {
    id: "mcas2",
    name: {
      th: "ขัดคราบยางมะตอย",
      en: "Asphalt Stain Removing",
    },
    price: {
      motorbike: 130,
      minibike: 130,
      bigbike: 130,
    },
    time: 30,
  },
  {
    id: "mcas3",
    name: {
      th: "ล้างและหล่อลื่นโซ่",
      en: "Chain Cleaning",
    },
    price: {
      motorbike: 200,
      minibike: 200,
      bigbike: 200,
    },
    time: 60,
  },
  {
    id: "mcas4",
    name: {
      th: "เคลือบแก้วหมวกกันน็อค 1 ใบ",
      en: "1 Helmet Coating",
    },
    price: {
      motorbike: 250,
      minibike: 250,
      bigbike: 250,
    },
    time: 120,
  },
];

const serviceMCList = [
  {
    value: "MC-WASHING",
    name: "ล้างสีรถมอเตอร์ไซค์",
    price: 120,
    time: 30,
  },
  {
    value: "MC-COATING",
    name: "เคลือบแก้วรถมอเตอร์ไซค์",
    price: 3500,
    time: 360,
  },
];

const serviceList = [
  {
    value: "CAR-WASHING",
    name: "ล้างสีดูดฝุ่น",
    price: 550,
    time: 60,
  },
  {
    value: "ENGINE-CLEANING",
    name: "ล้างห้องเครื่อง",
    price: 750,
    time: 60,
  },
  {
    value: "ASPHALT-STAIN-REMOVING",
    name: "ขัดคราบยางมะตอย",
    price: 950,
    time: 60,
  },
  {
    value: "HEADLIGHT-STAIN-REMOVING",
    name: "ขัดคราบไฟหน้า/ไฟหน้าเหลือง",
    price: 1250,
    time: 150,
  },
  {
    value: "WETLOOK",
    name: "ขัดสี/ขัดชักเงา/ขัดลบรอย",
    price: 1590,
    time: 210,
  },
  {
    value: "SEAT-CLEANING",
    name: "ฟอกเบาะพรม/หนัง",
    price: 1990,
    time: 360,
  },
  {
    value: "CAR-COATING",
    name: "เคลือบแก้วรถยนต​์",
    price: 5500,
    time: 360,
  },
];

export {
  serviceList,
  serviceMCList,
  mainPackageServices,
  additionalServices,
  mcMainPackageServices,
  mcAdditionalServices,
};
