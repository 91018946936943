import {
  FaMotorcycle,
  FaCarSide,
  FaShuttleVan,
  FaTruckPickup,
} from "react-icons/fa";
import { GiCityCar } from "react-icons/gi";

const carColorList = [
  {
    id: "white",
    name: {
      th: "สีขาว",
      en: "White",
    },
    text: "text-white",
    bg: "bg-gray-800",
  },
  {
    id: "black",
    name: {
      th: "สีดำ",
      en: "Black",
    },
    text: "text-black",
    bg: "bg-gray-200",
  },
  {
    id: "gray",
    name: {
      th: "สีเทา",
      en: "Gray",
    },
    text: "text-gray-500",
    bg: "bg-gray-200",
  },
  {
    id: "silver",
    name: {
      th: "สีเงิน",
      en: "Silver",
    },
    text: "text-gray-300",
    bg: "bg-gray-800",
  },
  {
    id: "red",
    name: {
      th: "สีแดง",
      en: "Red",
    },
    text: "text-red-500",
    bg: "bg-gray-800",
  },
  {
    id: "blue",
    name: {
      th: "สีน้ำเงิน",
      en: "Blue",
    },
    text: "text-blue-500",
    bg: "bg-gray-800",
  },
  {
    id: "brown",
    name: {
      th: "สีน้ำตาล",
      en: "Brown",
    },
    text: "text-brown-500",
    bg: "bg-gray-800",
  },
  {
    id: "green",
    name: {
      th: "สีเขียว",
      en: "Green",
    },
    text: "text-green-500",
    bg: "bg-gray-800",
  },
  {
    id: "beige",
    name: {
      th: "สีเบจ",
      en: "Beige",
    },
    text: "text-beige-500",
    bg: "bg-gray-200",
  },
  {
    id: "orange",
    name: {
      th: "สีส้ม",
      en: "Orange",
    },
    text: "text-orange-500",
    bg: "bg-gray-800",
  },
  {
    id: "gold",
    name: {
      th: "สีทอง",
      en: "Gold",
    },
    text: "text-yellow-500",
    bg: "bg-gray-800",
  },
  {
    id: "yellow",
    name: {
      th: "สีเหลือง",
      en: "Yellow",
    },
    text: "text-yellow-500",
    bg: "bg-gray-800",
  },
  {
    id: "purple",
    name: {
      th: "สีม่วง",
      en: "Purple",
    },
    text: "text-purple-500",
    bg: "bg-gray-800",
  },
  {
    id: "others",
    name: {
      th: "สีอื่นๆ",
      en: "Others",
    },
    text: "text-gray-500",
    bg: "bg-gray-800",
  },
];

const carTypeList = [
  {
    id: "car",
    name: {
      th: "รถยนต์",
      en: "Car",
    },
    list: [
      {
        id: "sedan",
        name: {
          th: "รถเก๋ง",
          en: "Sedan",
        },
        describe: "เช่น Civic, Altis, City, ...",
        icon: FaCarSide,
      },
      {
        id: "pickup",
        name: {
          th: "รถกระบะ",
          en: "Pickup",
        },
        describe: "เช่น Hilux, D-Max, Ranger, ...",
        icon: FaTruckPickup,
      },
      {
        id: "suv",
        name: {
          th: "รถSUV",
          en: "SUV",
        },
        describe: "เช่น Fortuner, Mu-X, Everest, ...",
        icon: GiCityCar,
      },
      {
        id: "mpv",
        name: {
          th: "รถMPV",
          en: "MPV",
        },
        describe: "เช่น Alphard, Vellfire, ...",
        icon: FaShuttleVan,
      },
    ],
  },
  {
    id: "motorcycle",
    name: {
      th: "รถจักรยานยนต์",
      en: "Motorcycle",
    },
    list: [
      {
        id: "motorbike",
        name: {
          th: "รถจักรยานยนต์เล็ก",
          en: "Motorcycle",
        },
        describe: "ขนาดไม่เกิน 125cc",
        icon: FaMotorcycle,
      },
      {
        id: "minibike",
        name: {
          th: "รถจักรยานยนต์กลาง",
          en: "Mid Motorcycle",
        },
        describe: "ขนาด 125-400cc",
        icon: FaMotorcycle,
      },
      {
        id: "bigbike",
        name: {
          th: "รถบิ๊กไบค์",
          en: "Bigbike",
        },
        describe: "ขนาดเกิน 400cc",
        icon: FaMotorcycle,
      },
    ],
  },
];

export { carColorList, carTypeList };
