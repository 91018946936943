/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// database

// components
import { FaPhone, FaUser } from "react-icons/fa6";
import Header from "../../components/header/Header";
import MainBox from "../../components/layout/MainBox";

// utils
import { RootContext } from "../app/Root";
import UserAutoComplete from "../bill/components/UserAutoComplete";
import InputText from "../../components/form/InputText";
import { realtimeGetUsers, updateUser } from "../../database/user";
import { addPoint } from "../../database/point";

export default function AddPoint() {
  const rootContext = useContext(RootContext);
  const navigate = useNavigate();

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { isLogin, userData } = rootContext.user;
  const { isLoading, incLoading, decLoading, checkLoading } =
    rootContext.loading;

  let nameRef = useRef(null);
  let [name, setName] = useState("");
  let [nameError, setNameError] = useState(false);

  let phoneNumberRef = useRef(null);
  let [phoneNumber, setPhoneNumber] = useState("");
  let [phoneNumberError, setPhoneNumberError] = useState(false);

  let customerRef = useRef(null);
  let [customers, setCustomers] = useState([]);
  let [displayCustomers, setDisplayCustomers] = useState([]);
  let [selectedCustomer, setSelectedCustomer] = useState(null);

  const onChangePhoneNumberInput = (e) => {
    const newValue = e.target.value;
    const pattern = /^\d{0,10}$/;
    const isValidNewValue = pattern.test(newValue);
    if (newValue === "" || isValidNewValue) {
      setPhoneNumber(newValue);
      setPhoneNumberError(false);
    }
  };

  const onChangeNameInput = (e) => {
    const newValue = e.target.value;
    const pattern = /^[ก-๙a-zA-Z]+$/;
    const isValidNewValue = pattern.test(newValue);

    if (newValue === "" || isValidNewValue) {
      setName(newValue);
      setNameError(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!isLogin || userData?.type !== "employee") {
      pushToast("โปรดเข้าสู่ระบบด้วยบัญชีพนักงาน!", "warn");
      return;
    }

    if (!selectedCustomer) {
      pushToast("โปรดเลือกลูกค้า!", "warn");
      return;
    }

    if (name || phoneNumber) {
      if (name === "") {
        nameRef.current.focus();
        setNameError(true);
        pushToast("โปรดกรอกชื่อ!", "warn");
        return;
      }

      if (phoneNumber.length !== 10) {
        phoneNumberRef.current?.focus();
        setPhoneNumberError(true);
        pushToast("โปรดกรอกเบอร์โทร!", "warn");
        return;
      }

      const userData = {
        profile: {
          name,
          phone: phoneNumber,
        },
      };

      incLoading("updateUser");
      const resUpdateUser = await updateUser(selectedCustomer.uid, userData);
      decLoading("updateUser");

      if (!resUpdateUser.ok) {
        pushToast("ไม่สามารถบันทึกข้อมูลลูกค้า โปรดลองใหม่อีกครั้ง!", "danger");
        return;
      }
    }

    const { uid: customerUid, line } = selectedCustomer;
    const { displayName: custName } = line;

    incLoading("addPoint");
    const resAddPoint = await addPoint(customerUid);
    decLoading("addPoint");

    if (resAddPoint.ok) {
      return openAlert(
        "เพิ่มแต้มสำเร็จ",
        `เพิ่มแต้มให้ ${custName} สำเร็จ!`,
        "ตกลง",
        () => {
          navigate("/point/all");
        }
      );
    } else {
      pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
      return;
    }
  };

  useEffect(() => {
    if (isLogin && userData?.type === "employee") {
      incLoading("realtimeGetUsers");
      realtimeGetUsers(setCustomers, "all", "all");
    }
  }, [isLogin]);

  useEffect(() => {
    if (customers) {
      decLoading("realtimeGetUsers");
      setDisplayCustomers(customers);
    }
  }, [customers]);

  useEffect(() => {
    if (selectedCustomer) {
      const { profile } = selectedCustomer;
      if (profile) {
        setName(profile?.name);
        setPhoneNumber(profile?.phone);
      } else {
        setName("");
        setPhoneNumber("");
      }
    }
  }, [selectedCustomer]);

  return (
    <div className=" min-h-screen">
      <Header
        title="ระบบเพิ่มแต้มลูกค้า"
        requireType="employee"
        userType={userData?.type}
      />

      <div className="pt-36 pb-20">
        <MainBox>
          <form noValidate className="mx-4 mb-10 " onSubmit={onSubmit}>
            <div className="mt-6 flex-col max-w-sm mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลลูกค้า</h2>

              <UserAutoComplete
                placeholder="ไลน์ลูกค้า"
                ref={customerRef}
                users={displayCustomers}
                selected={[selectedCustomer, setSelectedCustomer]}
              />

              <InputText
                type="text"
                autoComplete="name"
                placeholder="ชื่อลูกค้า"
                Icon={FaUser}
                value={name}
                ref={nameRef}
                onChange={onChangeNameInput}
                disabled={isLoading}
                isError={nameError}
                required
              />

              <InputText
                type="tel"
                autoComplete="tel"
                pattern="(0)\d{8,9}"
                inputMode="numeric"
                placeholder="เบอร์โทร"
                Icon={FaPhone}
                value={phoneNumber}
                onChange={onChangePhoneNumberInput}
                ref={phoneNumberRef}
                disabled={isLoading}
                isError={phoneNumberError}
                required
              />
            </div>

            <div className="justify-center flex mt-5">
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-mainred px-4 py-3 w-full text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:animate-pulse"
                type="submit"
                disabled={checkLoading("createBooking")}>
                {checkLoading("createBooking") ? "กำลังโหลด..." : "บันทึก"}
              </button>
            </div>

            <div className=" pt-3 ">
              <p className=" text-center text-gray-400 text-xs">
                ร้านเคเอสอาร์ คาร์คลีน
              </p>
            </div>
          </form>
        </MainBox>
      </div>
    </div>
  );
}
