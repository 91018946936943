/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";

// components
import { FaChevronRight } from "react-icons/fa";
import HeadLanding from "../../components/header/HeadLanding";

const contents = [
  {
    image: "/images/landing/carwash.jpg",
    video: "/videos/landing/carwash.mp4",
    title: "ล้างสีดูดฝุ่น พรีเมี่ยม",
    subtitle: "ด้วยน้ำยาคุณภาพสูง",
    description:
      "เปิดบริการทุกวัน 8:30 - 18:30 น. ลานจอดรถชั้นล่างห้างโลตัสสงขลา",
    href: "carwash",
    buttonTitle: "อัตราค่าบริการ",
    isUse3m: true,
  },
  {
    image: "/images/landing/glasscoating.jpg",
    video: "/videos/landing/glasscoating.mp4",
    title: "เคลือบแก้วรถยนต์",
    subtitle: "ด้วยน้ำยาคุณภาพสูง",
    description:
      "การขัดผิวด้วยช่างฝีมือ อุปกรณ์มาตรฐาน และการดูแลหลังการขายดีเยี่ยม",
    href: "glasscoating",
    buttonTitle: "ข้อมูลเพิ่มเติม",
    isUse3m: true,
  },
  {
    image: "/images/landing/carseatcleanning.jpg",
    video: "/videos/landing/carseatcleanning.mp4",
    title: "ฟอกเบาะรถยนต์",
    subtitle: "ด้วยน้ำยาคุณภาพสูง และเครื่องมือทันสมัย",
    description: "สามารถฟอกเบาะพรม หรือเบาะหนังได้อย่างเหมือนใหม่",
    href: "carseatcleanning",
    buttonTitle: "อัตราค่าบริการ",
    isUse3m: false,
  },
  {
    image: "/images/landing/wetlook.jpg",
    video: "/videos/landing/wetlook.mp4",
    title: "ขัดชักเงา ขัดลบรอย",
    subtitle: "ขัดสีรถยนต์ คุณภาพสูง งานขัดปรับผิวส้มขั้นสูง",
    description:
      "ช่างฝีมือผู้เชี่ยวชาญ ประสบการณ์หลายปี พร้อมเครื่องมือคุณภาพสูง",
    href: "wetlook",
    buttonTitle: "ข้อมูลเพิ่มเติม",
    isUse3m: false,
  },
];

export default function IndexApp() {
  return (
    <div className="scroll-smooth bg-maindark">
      <HeadLanding />

      <div className="relative">
        {contents.map((item, idx) => (
          <div
            key={idx}
            id={item.href}
            className="relative w-full  min-h-screen ">
            <div className="absolute inset-0 bg-black opacity-70 z-10" />

            <img
              src={item.image}
              className="object-cover w-full h-full z-0 absolute"
              layout="fill"
              alt={item.title}
            />

            {/* <video
              autoPlay
              loop
              muted
              playsInline
              poster={item.image}
              controls={false}
              preload="auto"
              controlsList="nodownload"
              className="object-cover w-full h-full z-0 absolute">
              <source src={item.video} type="video/mp4" />
            </video> */}

            <div className=" absolute left-1/2 -translate-x-1/2 z-10 w-screen h-screen flex items-end overflow-x-hidden ">
              <main className=" h-fit flex flex-col flex-1 items-center mb-32 justify-center text-center ">
                <h1 className=" text-4xl sm:text-5xl font-medium text-white tracking-wide whitespace-nowrap ">
                  {item.title}
                </h1>
                <h2 className=" text-xl sm:text-2xl text-gray-100 mt-4 mb-8">
                  {item.subtitle + " "}
                  {item.isUse3m && (
                    <span className=" text-mainred font-bold">3M</span>
                  )}
                </h2>
                <p className="text-gray-100 whitespace-nowrap text-xs sm:text-xl">
                  {item.description}
                </p>

                <div className="flex mt-4">
                  <Link
                    // href={item.href}
                    to="/booking"
                    className="flex items-center bg-darkred text-white text-xl px-8 py-2 rounded-sm shadow-black shadow-lg hover:scale-110 transform duration-150">
                    {/* {item.buttonTitle} */}
                    จองคิวออนไลน์
                    <FaChevronRight className="w-6" />
                  </Link>
                </div>
              </main>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
