import {
  wh,
  wheres,
  addDocument,
  updateDocument,
  getDocumentById,
  realtimeGetAllDocumentByQuery,
} from "./database";
import { orderBy, Timestamp } from "firebase/firestore";

// --------- get realtime bookings ---------
export const realtimeGetBookings = (
  setBooking,
  startDate,
  endDate,
  status = "all"
) => {
  let queries = [
    ...wheres(
      wh("status", "==", status),
      wh("bookingDatetime", ">=", startDate, "date"),
      wh("bookingDatetime", "<=", endDate, "date")
    ),
    orderBy("bookingDatetime", "desc"),
  ];
  return realtimeGetAllDocumentByQuery(setBooking, "booking", queries);
};

// --------- get bookings ---------
export const getBooking = async (bookingId) => {
  return await getDocumentById("booking", bookingId);
};

// --------- add & update booking ---------
export const createBooking = async (
  bookingData,
  bookingDatetime,
  status = "created",
  date = new Date()
) => {
  const newBookingData = {
    ...bookingData,
    datetime: Timestamp.fromDate(date),
    bookingDatetime: Timestamp.fromDate(bookingDatetime),
    status,
  };

  return await addDocument("booking", newBookingData);
};

export const updateBooking = async (bookingId, bookingData = {}) => {
  return await updateDocument("booking", bookingId, bookingData);
};
