/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

// database
import { getUser } from "../../database/user";
import {
  getRedeems,
  realtimeGetRedeems,
  updateRedeem,
} from "../../database/redeem";

// components
import { FaTrash } from "react-icons/fa";
import { MdRedeem } from "react-icons/md";
import Table from "../../components/table/Table";
import Header from "../../components/header/Header";
import MainBox from "../../components/layout/MainBox";

// utils
import { RootContext } from "../app/Root";

const RedeemTableConfig = [
  {
    head: "วันเวลา",
    type: "datetime",
    data: "datetime",
  },
  {
    head: "ลูกค้า",
    type: "uidAvatar",
    data: "uid",
    align: "center",
  },
  {
    head: "จำนวนแต้ม",
    type: "text",
    data: "countPoint",
  },
  {
    head: "สถานะ",
    type: "text",
    data: "status",
  },
  {
    head: "ประเภท",
    type: "text",
    data: "redeemType",
  },
  {
    head: "รหัสการแลก",
    type: "text",
    data: "id",
  },
];

export default function Redeem() {
  const rootContext = useContext(RootContext);

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { openConfirm } = rootContext.confirm;
  const { isLogin, userData } = rootContext.user;
  const { incLoading, decLoading } = rootContext.loading;

  const [redeems, setRedeems] = useState(null);
  const [displayRedeems, setDisplayRedeems] = useState([]);

  // --------------- get data ---------------
  const getAllRedeem = async () => {
    const resGetRedeems = await getRedeems();
    decLoading("getAllRedeem");

    if (!resGetRedeems.ok) {
      return pushToast("โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!", "danger");
    }

    setRedeems(resGetRedeems.data);
  };

  useEffect(() => {
    if (isLogin && userData?.type === "employee") {
      incLoading("getAllRedeem");
      realtimeGetRedeems(setRedeems, "all");
    }
  }, [isLogin]);

  useEffect(() => {
    if (redeems) {
      decLoading("getAllRedeem");
      setDisplayRedeems(redeems);
    }
  }, [redeems]);

  const handleCancelRedeem = async ({ id: redeemId }) => {
    const resConfirm = await openConfirm(
      "ยกเลิกรายการแลกแต้ม",
      `คุณต้องการยกเลิกรายการแลกแต้มรหัส ${redeemId} ใช่หรือไม่?`
    );
    if (!resConfirm) return;

    incLoading("cancelRedeem");
    const resCancelRedeem = await updateRedeem(redeemId, {
      status: "canceled",
    });
    decLoading("cancelRedeem");

    if (!resCancelRedeem.ok) {
      return pushToast(`ไม่สามารถยกเลิกรายการแลกแต้มได้`, "danger");
    } else {
      getAllRedeem();
      openAlert(
        "ยกเลิกรายการแลกแต้มสำเร็จ",
        `ยกเลิกรายการแลกแต้มรหัส ${redeemId} เรียบร้อยแล้ว`
      );
    }
  };

  const handleConfirmRedeem = async ({ id: redeemId }) => {
    getAllRedeem();
    alert("ยืนยันการแลกแต้ม");
  };

  const checkDisabledConfirmRedeem = (redeem) => {
    if (redeem.status !== "pending") {
      return true;
    }

    if (redeem.redeemType !== "byCustomer") {
      return true;
    }

    return false;
  };

  const checkDisabledRedeem = (redeem) => {
    return redeem.status === "canceled";
  };

  return (
    <div className=" min-h-screen">
      <Header
        title="รายการแลกแต้ม"
        requireType="employee"
        userType={userData?.type}
      />

      <div className="pt-36 pb-20">
        <MainBox>
          <Table
            data={displayRedeems}
            config={RedeemTableConfig}
            option={{
              head: {
                type: "uidAvatar",
                data: "uid",
              },
              list: [
                {
                  icon: MdRedeem,
                  text: "ยืนยันการแลกแต้ม",
                  onClick: handleConfirmRedeem,
                  onDisabled: checkDisabledConfirmRedeem,
                },
                {
                  icon: FaTrash,
                  text: "ยกเลิกรายการแลกแต้ม",
                  onClick: handleCancelRedeem,
                  onDisabled: checkDisabledRedeem,
                },
              ],
            }}
          />
        </MainBox>
      </div>
    </div>
  );
}
