const LiffDemoUser = {
  userId: "U28980d4ba38d300cdd1d08396d1b4e73",
  displayName: "jarawin",
  pictureUrl:
    "https://firebasestorage.googleapis.com/v0/b/ksr-car-clean.appspot.com/o/IMG_8701.jpg?alt=media&token=0ffd1abd-ba90-430d-a49d-53c19657355f",
  statusMessage: "(j)(a)",
  email: "jarawin.promsawat@gmail.com",
};

export { LiffDemoUser };
