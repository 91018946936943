/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

// database
import { getUser } from "../../database/user";
import { getPointsByUid } from "../../database/point";
import { addRedeem, getRedeemsByUid } from "../../database/redeem";

// components
import { GiCrownCoin } from "react-icons/gi";
import Header from "../../components/header/Header";
import Table from "../../components/table/Table";
import LineAvatar from "../../components/LineAvatar";
import MainBox from "../../components/layout/MainBox";

// utils
import { RootContext } from "../app/Root";
import { closeLiff, selfMessages } from "../../services/lineMessaging";

const PointTableConfig = [
  {
    head: "วันที่",
    type: "date",
    data: "datetime",
    sortAble: true,
  },
  {
    head: "เวลา",
    type: "time",
    data: "datetime",
    sortAble: true,
  },
  {
    head: "รหัสแต้ม",
    type: "text",
    data: "id",
    sortAble: true,
  },
  {
    head: "สถานะ",
    type: "text",
    data: "status",
    sortAble: true,
  },
];

export default function Point() {
  const rootContext = useContext(RootContext);
  const location = useLocation();
  let { userId } = useParams();

  const [ownerUser, setOwnerUser] = useState(null);
  const [points, setPoints] = useState([]);
  const [activePoint, setActivePoint] = useState(0);

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { openConfirm } = rootContext.confirm;
  const { isLogin, userData } = rootContext.user;
  const { isLoading, incLoading, decLoading } = rootContext.loading;

  // ----------- count active points ------------
  const countActivePoints = (points) => {
    let count = 0;
    points.forEach((point) => {
      if (point.status === "active") {
        count++;
      }
    });
    setActivePoint(count);
  };

  useEffect(() => {
    countActivePoints(points);
  }, [points]);

  // ----------- handle use point ------------
  const handleUsePoint = async () => {
    if (activePoint < 10) {
      return pushToast("แต้มของคุณไม่พอ", "danger");
    }

    const resFindAllRedeem = await getRedeemsByUid(userData.uid, "pending");
    if (!resFindAllRedeem.ok) {
      return pushToast("ไม่สามารถแลกสิทธิ์ได้ โปรดลองใหม่อีกครั้ง", "danger");
    }

    if (resFindAllRedeem.data?.length > 0) {
      return openAlert(
        "มีการแลกสิทธิ์รอการตรวจสอบ",
        "คุณมีการแลกสิทธิ์รอการตรวจสอบอยู่ โปรดรอการตรวจสอบก่อน",
        "ตกลง",
        () => {},
        true
      );
    }

    const isConfirm = await openConfirm(
      "ยืนยันการใช้แต้ม",
      "คุณต้องการใช้ 10 แต้มเพื่อแลกสิทธิ์ล้างสีรถฟรี ใช่หรือไม่?"
    );
    if (!isConfirm) {
      return;
    }

    const pointIds = getActivePointIds(points, 10);
    const resAddRedeem = await addRedeem(
      userData.uid,
      userData.uid,
      pointIds,
      "byCustomer"
    );
    if (!resAddRedeem.ok) {
      return pushToast("ไม่สามารถแลกสิทธิ์ได้ โปรดลองใหม่อีกครั้ง", "danger");
    }

    if (isLogin) {
      selfMessages([
        {
          type: "text",
          text: `กำลังแลกสิทธิ์ล้างสีรถฟรี... (ด้วยคะแนน 10 แต้ม)`,
        },
      ]);
    }

    openAlert(
      "การใช้สิทธิ์ล้างสีรถฟรี",
      "กำลังแลกสิทธิ์ล้างสีรถฟรี... (ด้วยคะแนน 10 แต้ม)",
      "ตกลง",
      () => {
        if (isLogin) {
          closeLiff();
        } else {
          window.location.reload();
        }
      },
      false
    );
  };

  const getActivePointIds = (points, amount = 10) => {
    return points
      .filter((point) => point.status === "active")
      .map((point) => point.id)
      .slice(0, amount);
  };

  // ----------- fetch points ------------
  const fetchPoints = async (uid, status) => {
    incLoading("fetchUser");
    const resFindUser = await getUser(uid);
    decLoading("fetchUser");

    if (!resFindUser.ok) {
      return pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
    }

    if (!resFindUser.data) {
      return openAlert(
        "ไม่พบข้อมูลผู้ใช้",
        "ไม่พบข้อมูลผู้ใช้ #" + userId,
        "ตกลง",
        () => {},
        true
      );
    } else {
      setOwnerUser(resFindUser.data);
    }

    incLoading("fetchPoints");
    const resFindAllPoint = await getPointsByUid(uid, status);
    decLoading("fetchPoints");

    if (!resFindAllPoint.ok) {
      return pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
    }

    setPoints(resFindAllPoint.data);
  };

  useEffect(() => {
    if (userId) {
      const queryParams = new URLSearchParams(location.search);
      const status = queryParams.get("status") || "all";
      fetchPoints(userId, status);
    } else if (isLogin) {
      fetchPoints(userData?.uid);
    }
  }, [userId, isLogin]);

  return (
    <div className=" min-h-screen ">
      <Header
        title={`แต้มพร้อมใช้งานรวม ${activePoint} แต้ม`}
        requireType={!ownerUser && "customer"}
        userType={ownerUser?.type}
      />

      <div className="pt-36 pb-20">
        {isLogin && !isLoading && (userData?.uid === userId || !userId) && (
          <MainBox>
            <div
              className={`rounded-lg ${
                activePoint >= 10
                  ? "bg-amber-400 border border-amber-400 cursor-pointer hover:scale-105 "
                  : "bg-gray-400 border border-gray-400"
              } `}
              onClick={handleUsePoint}>
              <GiCrownCoin className="text-9xl text-white mx-auto" />
              <h1 className="text-base text-center text-white p-2 font-bold">
                ใช้ 10 แต้มเพื่อแลกสิทธิ์ล้างสีรถฟรี
              </h1>
            </div>
          </MainBox>
        )}

        {ownerUser && ownerUser?.uid !== userData?.uid && (
          <MainBox>
            <div className=" flex justify-center p-4">
              <LineAvatar user={ownerUser} />
            </div>
          </MainBox>
        )}

        <MainBox>
          <Table data={points} config={PointTableConfig} />
        </MainBox>
      </div>
    </div>
  );
}
