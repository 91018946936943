import React from "react";

export default function MainBox({ children }) {
  return (
    <div className=" w-full mt-2 max-w-[414px] bg-white mx-auto  pt-1">
      <div className="max-w-sm relative w-[22rem] mx-auto bg-white border border-gray-200 rounded-lg shadow">
        {children}
      </div>
    </div>
  );
}
