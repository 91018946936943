import {
  wh,
  wheres,
  addDocument,
  getAllDocumentByQuery,
  realtimeGetAllDocumentByQuery,
} from "./database";
import { orderBy, Timestamp } from "firebase/firestore";

// --------- get realtime points ---------
export const realtimeGetPoints = (setPoints, status = "all") => {
  let queries = [
    ...wheres(wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return realtimeGetAllDocumentByQuery(setPoints, "point", queries);
};

// --------- get points ---------
export const getPoints = async (status = "all") => {
  let queries = [
    ...wheres(wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return await getAllDocumentByQuery("point", queries);
};

export const getPointsByUid = async (uid, status = "all") => {
  let queries = [
    ...wheres(wh("uid", "==", uid), wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return await getAllDocumentByQuery("point", queries);
};

// --------- add point ---------
export const addPoint = async (uid, status = "active", date = new Date()) => {
  const pointData = {
    datetime: Timestamp.fromDate(date),
    status,
    uid,
  };

  return await addDocument("point", pointData);
};
