import "datejs";

export const convertTimestampToDate = (timestamp) => {
  const milliseconds = timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1e6;
  return new Date(milliseconds);
};

export function isTimeAfter(
  timeString,
  currentDate = new Date(),
  targetDate = new Date()
) {
  const [hours, minutes] = timeString.split(":").map(Number);
  targetDate.setHours(hours, minutes, 0, 0);
  return currentDate > targetDate;
}

export const isToday = (timestamp) => {
  const today = new Date();
  const date = convertTimestampToDate(timestamp);

  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  const todayDay = today.getDate();

  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth();
  const dateDay = date.getDate();

  return (
    todayYear === dateYear && todayMonth === dateMonth && todayDay === dateDay
  );
};

export function areSameDate(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const subtractDate = (
  date = new Date(),
  day = 0,
  month = 0,
  year = 0
) => {
  date.setDate(date.getDate() - day);
  date.setMonth(date.getMonth() - month);
  date.setFullYear(date.getFullYear() - year);

  return date;
};

export const getDateRangeForMonth = (monthIdx, year) => {
  if (monthIdx < 0 || monthIdx > 11) {
    throw new Error(
      "Invalid month index. Month index should be between 0 and 11."
    );
  }

  const startDate = new Date(year, monthIdx, 1);
  const endDate = new Date(year, monthIdx + 1, 0);
  return {
    startDate,
    endDate,
  };
};

export const getLast12MonthNames = () => {
  const months = [];
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let i = 0; i < 12; i++) {
    const date = new Date(currentYear, currentMonth - i, 1);
    const monthName = date.toLocaleString("th-TH", {
      month: "long",
      year: "numeric",
    });
    months.push(monthName);
  }

  return months;
};

export const parseThaiMonthAndYear = (input) => {
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const [monthName, yearStr] = input.split(" ");
  const year = parseInt(yearStr, 10) - 543;
  const monthIdx = thaiMonths.findIndex((month) => month === monthName);

  return {
    monthIdx,
    year,
  };
};

export const getNDayList = (numberOfPastDays, numberOfFutureDays) => {
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - numberOfPastDays);

  const days = [];
  for (let i = 0; i < numberOfPastDays + numberOfFutureDays + 1; i++) {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + i);

    const formattedDate = `${date.getDate()} ${thaiMonths[date.getMonth()]} ${
      date.getFullYear() + 543
    }`;

    const startDate = new Date(date);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(date);
    endDate.setHours(23, 59, 59, 999);

    days.push({ name: formattedDate, value: startDate, startDate, endDate });
  }

  return days;
};

export const getNMonthList = (numberOfPastMonths, numberOfFutureMonths) => {
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const currentDate = Date.today();
  currentDate.addMonths(-numberOfPastMonths);

  const months = [];
  for (let i = 0; i < numberOfPastMonths + numberOfFutureMonths + 1; i++) {
    const startDate = Date.today().addMonths(i - numberOfPastMonths);
    startDate.moveToFirstDayOfMonth();

    const endDate = startDate.clone().moveToLastDayOfMonth();

    const formattedStartDate = `${thaiMonths[startDate.getMonth()]} ${
      startDate.getFullYear() + 543
    }`;

    console.log(formattedStartDate);

    months.push({
      name: formattedStartDate,
      value: startDate,
      startDate,
      endDate,
    });
  }

  return months;
};

export const getNWeekList = (numberOfPastWeeks, numberOfFutureWeeks) => {
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - numberOfPastWeeks * 7);

  const weeks = [];
  for (let i = 0; i < numberOfPastWeeks + numberOfFutureWeeks + 1; i++) {
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() + i * 7);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    endDate.setHours(23, 59, 59, 999);

    const formattedStartDate = `${startDate.getDate()} ${
      thaiMonths[startDate.getMonth()]
    } ${endDate.getFullYear() - 1957}`;

    const formattedEndDate = `${endDate.getDate()} ${
      thaiMonths[endDate.getMonth()]
    } ${endDate.getFullYear() - 1957}`;

    const formattedWeek = `${formattedStartDate} - ${formattedEndDate}`;

    weeks.push({
      name: formattedWeek,
      value: startDate,
      startDate,
      endDate,
    });
  }

  return weeks;
};

export const getNextNDays = (numberOfDays, skipDate = 0) => {
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const thaiDays = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];

  const currentDate = new Date();
  const days = [];

  for (let i = 0; i < numberOfDays; i++) {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + i + skipDate);

    const formattedDate = `${thaiDays[date.getDay()]} ${date.getDate()} ${
      thaiMonths[date.getMonth()]
    } ${date.getFullYear() + 543}`;
    days.push({
      title: formattedDate,
      name: formattedDate,
      value: date,
    });
  }

  return days;
};

export const parseThaiDateStringToDate = (dateString) => {
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const parts = dateString.split(" ");
  const day = parseInt(parts[0], 10);
  const month = thaiMonths.findIndex((month) => month === parts[1]);
  const year = parseInt(parts[2], 10) - 543; // Convert Thai year to Gregorian calendar

  return new Date(year, month, day);
};

export const generateHalfHourIntervals = (
  start,
  end,
  date,
  now = new Date()
) => {
  const intervals = [];

  const startTime = new Date(date);
  startTime.setHours(
    parseInt(start.split(":")[0], 10),
    parseInt(start.split(":")[1], 10),
    0,
    0
  );

  const endTime = new Date(date);
  endTime.setHours(
    parseInt(end.split(":")[0], 10),
    parseInt(end.split(":")[1], 10),
    0,
    0
  );

  let currentInterval = new Date(startTime);

  // If the date is now, start from the next half or full hour from now
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    if (now.getHours() >= currentInterval.getHours()) {
      if (now.getMinutes() >= 30) {
        currentInterval.setHours(now.getHours() + 1, 30, 0, 0);
      } else {
        currentInterval.setHours(now.getHours() + 1, 0, 0, 0);
      }
    }
  }

  // Generate intervals
  while (currentInterval <= endTime) {
    intervals.push({
      name: `${currentInterval.toLocaleTimeString("th-TH", {
        hour: "2-digit",
        minute: "2-digit",
      })} น.`,
      value: new Date(currentInterval),
    });

    currentInterval.setMinutes(currentInterval.getMinutes() + 30);
  }

  return intervals;
};

export function combineDateAndTime(date, time) {
  if (!(date instanceof Date) || !(time instanceof Date)) {
    throw new Error("Both parameters must be instances of Date.");
  }

  const combinedDateTime = new Date(date);

  combinedDateTime.setHours(time.getHours());
  combinedDateTime.setMinutes(time.getMinutes());
  combinedDateTime.setSeconds(time.getSeconds());
  combinedDateTime.setMilliseconds(time.getMilliseconds());

  return combinedDateTime;
}

export function convertTimeStringToDate(timeString, date = new Date()) {
  var timeParts = timeString.split(":");
  date.setHours(parseInt(timeParts[0]));
  date.setMinutes(parseInt(timeParts[1]));
  return date;
}

export function getThaiDuration(datetime1) {
  const now = new Date();
  const diffInMs = now - datetime1;

  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisecondsPerMonth = millisecondsPerDay * 30.436875; // average month length
  const millisecondsPerYear = millisecondsPerDay * 365.25; // average year length

  const years = Math.floor(diffInMs / millisecondsPerYear);
  const months = Math.floor(
    (diffInMs % millisecondsPerYear) / millisecondsPerMonth
  );
  const days = Math.floor(
    ((diffInMs % millisecondsPerYear) % millisecondsPerMonth) /
      millisecondsPerDay
  );

  let textList = [];
  if (years > 0) {
    textList.push(`${years} ปี`);
  }

  if (months > 0) {
    textList.push(`${months} เดือน`);
  }

  if (days > 0) {
    textList.push(`${days} วัน`);
  }

  return textList.join(" ");
}

export function isInLastMinutes(date, min = 5) {
  const now = new Date();
  const diffInMilliseconds = now - date;
  const diffInMinutes = diffInMilliseconds / (1000 * 60);
  return diffInMinutes <= min;
}

export function formatTimeAgo(date) {
  const now = new Date();
  const diffInMilliseconds = now - date;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

  if (diffInSeconds < 60) {
    return "ตอนนี้";
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} นาที`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} ชั่วโมง`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} วัน`;
  }
}

export const getHHMM = (date) => {
  return date.toLocaleTimeString("th-TH", {
    hour: "2-digit",
    minute: "2-digit",
  });
};
