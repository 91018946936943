import { useEffect } from "react";
import KSRImg from "../../images/KSRImg";

export default function LoadingScreen({ isLoading }) {
  // background cannot scroll
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);

  if (isLoading || 0) {
    return (
      <div className=" items-center fixed bg-black/80 z-50 h-screen w-screen flex justify-center ">
        <div className=" flex-col justify-center items-center animate-pulse">
          <KSRImg
            logoColor="#fff"
            lineColor="#fff"
            ksrColor="#fff"
            carcleanColor="#fff"
            className="text-white w-36 h-18"
          />
          <p className=" text-center text-gray-50 mt-2 ">กำลังโหลด... </p>
        </div>
      </div>
    );
  }
}
