import liff from "@line/liff";

export const selfMessages = (messages) => {
  if (liff.isInClient()) {
    return liff.sendMessages(messages);
  }
};

export const closeLiff = () => {
  if (liff.isInClient()) {
    liff.closeWindow();
  }
};
