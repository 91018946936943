/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import { IoIosTime } from "react-icons/io";
import { MdSpeakerNotes } from "react-icons/md";
import { FaPhone, FaUser, FaCarRear } from "react-icons/fa6";
import Header from "../../components/header/Header";
import InputText from "../../components/form/InputText";
import AddServiceSelection from "../../components/form/AddServiceSelection";
import CustomInputSelection from "../../components/form/CustomInputSelection";
import InputSelection from "../../components/form/InputSelection";
import UserAutoComplete from "./components/UserAutoComplete";

// database
import { carColorList, carTypeList } from "../../data/car";
import {
  mainPackageServices,
  mcMainPackageServices,
  additionalServices,
  mcAdditionalServices,
} from "../../data/service";
import { promotionList } from "../../data/promotion";
import { realtimeGetUsers } from "../../database/user";
import { addBill } from "../../database/bill";

// utils
import {
  getHHMM,
  getNDayList,
  convertTimeStringToDate,
} from "../../utils/datetime";
import { RootContext } from "../app/Root";

const DefaultPromotion = {
  id: "none",
  pid: "P0",
  name: { th: "เลือกโปรโมชั่น", en: "Select promotion." },
};

const DefaultMainService = {
  id: "none",
  pid: "P0",
  services: [],
  name: { th: "เลือกบริการหลัก", en: "Select main service." },
};

const MainServiceList = [
  DefaultMainService,
  ...mainPackageServices.flatMap((service) => service.packages),
];

const MCMainServiceList = [
  DefaultMainService,
  ...mcMainPackageServices.flatMap((service) => service.packages),
];

const MainPromotionList = [DefaultPromotion, ...promotionList];

export default function AddBill() {
  const navigate = useNavigate();
  const rootContext = useContext(RootContext);

  let { bookingId } = useParams();

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { userData, isLogin } = rootContext.user;
  const { isLoading, incLoading, decLoading, checkLoading } =
    rootContext.loading;

  let nameRef = useRef(null);
  let [name, setName] = useState("");
  let [nameError, setNameError] = useState(false);

  let phoneNumberRef = useRef(null);
  let [phoneNumber, setPhoneNumber] = useState("");
  let [phoneNumberError, setPhoneNumberError] = useState(false);

  let noteRef = useRef(null);
  let [note, setNote] = useState("");
  let [noteError, setNoteError] = useState(false);

  let licensePlateRef = useRef(null);
  let [licensePlate, setLicensePlate] = useState("");
  let [licensePlateError, setLicensePlateError] = useState(false);

  let dateRef = useRef(null);
  let dateListMain = getNDayList(7, 0);
  let [selectedDate, setSelectedDate] = useState(dateListMain[7]);

  let timeRef = useRef(null);
  let [timeString, setTimeString] = useState(getHHMM(new Date()));
  let [timeError, setTimeError] = useState(false);

  let carColorRef = useRef(null);
  let [selectedCarColor, setSelectedCarColor] = useState(carColorList[0]);

  let vehicleTypeRef = useRef(null);
  let [selectedVehicleType, setSelectedVehicleType] = useState(carTypeList[0]);

  let carTypeRef = useRef(null);
  let [selectedCarType, setSelectedCarType] = useState(
    selectedVehicleType.list[0]
  );

  let promotionRef = useRef(null);
  let [promotionListMain, setPromotionListMain] = useState(promotionList);
  let [selectedPromotion, setSelectedPromotion] = useState(DefaultPromotion);

  let serviceRef = useRef(null);
  let [serviceListMain, setServiceListMain] = useState(MainServiceList);
  let [selectedService, setSelectedService] = useState(DefaultMainService);

  let addServiceRef = useRef(null);
  let [additionalListMain, setAdditionalListMain] =
    useState(additionalServices);
  let [addSelectedService, setAddSelectedService] = useState([]);

  let customerRef = useRef(null);
  let [customers, setCustomers] = useState([]);
  let [displayCustomers, setDisplayCustomers] = useState([]);
  let [selectedCustomer, setSelectedCustomer] = useState(null);

  let [totalPrice, setTotalPrice] = useState({
    total: 0,
    discount: 0,
    net: 0,
  });

  const onChangeTimeInput = (e) => {
    const newValue = e.target.value;
    const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    const isValidNewValue = pattern.test(newValue);

    if (newValue === "") {
      setTimeString(getHHMM(new Date()));
      setTimeError(false);
    } else if (isValidNewValue) {
      setTimeString(newValue);
      setTimeError(false);
    }
  };

  const onChangePhoneNumberInput = (e) => {
    const newValue = e.target.value;
    const pattern = /^\d{0,10}$/;
    const isValidNewValue = pattern.test(newValue);
    if (newValue === "" || isValidNewValue) {
      setPhoneNumber(newValue);
      setPhoneNumberError(false);
    }
  };

  const onChangeNameInput = (e) => {
    const newValue = e.target.value;
    const pattern = /^[ก-๙a-zA-Z]+$/;
    const isValidNewValue = pattern.test(newValue);

    if (newValue === "" || isValidNewValue) {
      setName(newValue);
      setNameError(false);
    }
  };

  const onChangeNoteInput = (e) => {
    const newValue = e.target.value;
    const isValidNewValue = newValue.length <= 50;

    if (newValue === "" || isValidNewValue) {
      setNote(newValue);
      setNoteError(false);
    }
  };

  const onChangeLicensePlateInput = (e) => {
    const newValue = e.target.value;
    const isValidNewValue = newValue.length <= 50;

    if (newValue === "" || isValidNewValue) {
      setLicensePlate(newValue);
      setLicensePlateError(false);
    }
  };

  const clearInputsValus = () => {
    setName("");
    setNameError(false);
    setPhoneNumber("");
    setPhoneNumberError(false);
    setNote("");
    setNoteError(false);
    setLicensePlate("");
    setLicensePlateError(false);
    setSelectedDate(dateListMain[7]);
    setTimeString(getHHMM(new Date()));
    setTimeError(false);
    setSelectedCarColor(carColorList[0]);
    setSelectedVehicleType(carTypeList[0]);
    setSelectedCarType(selectedVehicleType.list[0]);
    setPromotionListMain(promotionList);
    setSelectedPromotion(DefaultPromotion);
    setServiceListMain(MainServiceList);
    setSelectedService(DefaultMainService);
    setAdditionalListMain(additionalServices);
    setAddSelectedService([]);
    setCustomers([]);
    setSelectedCustomer(null);
    setTotalPrice({
      total: 0,
      discount: 0,
      net: 0,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!isLogin || userData?.type !== "employee") {
      pushToast("โปรดเข้าสู่ระบบด้วยบัญชีพนักงาน!", "warn");
      return;
    }

    let pass = true;

    if (timeString === "") {
      timeRef.current?.focus();
      setTimeError(true);
      if (pass) {
        pushToast("โปรดกรอกเวลาที่ถูกต้อง!", "warn");
      }
      pass = false;
    }

    if (licensePlate === "") {
      licensePlateRef.current?.focus();
      setLicensePlateError(true);
      if (pass) {
        pushToast("โปรดกรอกป้ายทะเบียน!", "warn");
      }
      pass = false;
    }

    if (selectedService.id === "none" && addSelectedService.length === 0) {
      serviceRef.current?.focus();
      if (pass) {
        pushToast("โปรดเลือกบริการ!", "warn");
      }
      pass = false;
    }

    if (phoneNumber.length !== 10) {
      phoneNumberRef.current?.focus();
      setPhoneNumberError(true);
      if (pass) {
        pushToast("โปรดกรอกเบอร์โทร!", "warn");
      }
      pass = false;
    }

    if (!pass) {
      return;
    }

    const startDatetime = convertTimeStringToDate(
      timeString,
      selectedDate.value
    );

    const newBillData = {
      note,
      customer: {
        uid: selectedCustomer?.uid,
        name,
        phone: phoneNumber,
      },
      car: {
        vehicle: selectedVehicleType.id,
        type: selectedCarType.id,
        color: selectedCarColor.id,
        plate: licensePlate,
      },
      service: {
        main: selectedService,
        additional: addSelectedService,
        promotion: selectedPromotion,
      },
    };

    // remove customer uid is not exist
    if (!selectedCustomer?.uid) {
      delete newBillData.customer.uid;
    }

    incLoading("addBill");
    const resCreateBill = await addBill(newBillData, startDatetime);
    decLoading("addBill");

    if (resCreateBill.ok) {
      pushToast(`เพิ่มบิลสำเร็จ`);
      clearInputsValus();
      return;
    } else {
      pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
      return;
    }
  };

  useEffect(() => {
    setSelectedCarType(selectedVehicleType.list[0]);
    setPromotionListMain(MainPromotionList);
    setSelectedPromotion(DefaultPromotion);
    setSelectedService(DefaultMainService);
    setAddSelectedService([]);

    switch (selectedVehicleType.id) {
      case "motorcycle":
        setServiceListMain(MCMainServiceList);
        setAdditionalListMain(mcAdditionalServices);
        break;
      case "car":
        setServiceListMain(MainServiceList);
        setAdditionalListMain(additionalServices);
        break;
    }
  }, [selectedVehicleType]);

  const calculateTotalPrice = (service, carType) => {
    let total = 0;
    let discount = 0;

    if (service.main.id !== "none") {
      total += service.main.price[carType];
    }

    service?.additional.forEach((service) => {
      total += service.price[carType];
    });

    if (service.promotion.id !== "none") {
      if (service.promotion.type === "discount") {
        if (service.promotion.discount.type === "price") {
          discount -= service.promotion.discount.value;
        } else if (service.promotion.discount.type === "percent") {
          discount -= total * (service.promotion.discount.value / 100);
        }
      }
    }

    return {
      total,
      discount,
      net: total + discount,
    };
  };

  useEffect(() => {
    setPromotionListMain(MainPromotionList);
    setSelectedPromotion(DefaultPromotion);
    setAddSelectedService([]);
  }, [selectedService]);

  useEffect(() => {
    setTotalPrice(
      calculateTotalPrice(
        {
          main: selectedService,
          additional: addSelectedService,
          promotion: selectedPromotion,
        },
        selectedCarType.id
      )
    );
  }, [selectedService, addSelectedService, selectedPromotion, selectedCarType]);

  useEffect(() => {
    if (isLogin && userData?.type === "employee") {
      incLoading("realtimeGetUsers");
      realtimeGetUsers(setCustomers, "customer", "all");
    }
  }, [isLogin]);

  useEffect(() => {
    if (customers) {
      decLoading("realtimeGetUsers");
      setDisplayCustomers(customers);
    }
  }, [customers]);

  return (
    <>
      <Header
        title="บันทึกรายการบิลรถเข้า"
        requireType="employee"
        userType={userData?.type}
      />

      <div className=" w-full max-w-[414px] bg-white mx-auto min-h-screen pt-1">
        <div className="max-w-sm relative mt-36 mb-20 w-[22rem] mx-auto  bg-white border sm:px-4 border-gray-200 rounded-lg shadow ">
          <form noValidate className="mx-4 mb-10 " onSubmit={onSubmit}>
            <div className="mt-6 flex-col  mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลวันเวลารถเข้า</h2>

              <InputSelection
                ref={dateRef}
                list={dateListMain}
                selected={[selectedDate, setSelectedDate]}
              />

              <InputText
                type="time"
                placeholder={timeString}
                autoComplete="time-in"
                Icon={IoIosTime}
                disabled={isLoading}
                onChange={onChangeTimeInput}
                isError={timeError}
                ref={timeRef}
                value={timeString}
                required
              />

              <InputText
                type="text"
                autoComplete="note"
                placeholder="หมายเหตุ"
                Icon={MdSpeakerNotes}
                value={note}
                ref={noteRef}
                onChange={onChangeNoteInput}
                disabled={isLoading}
                isError={noteError}
                required
              />
            </div>

            {/* <div className="mt-6 flex-col  mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลพนักงาน</h2>

              <UserAutoComplete isError={false} placeholder="ไลน์พนักงานขาย" />

              <MultiUserAutoComplete />
            </div> */}

            <div className="mt-6 flex-col  mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลรถ</h2>

              <CustomInputSelection
                ref={vehicleTypeRef}
                list={carTypeList}
                selected={[selectedVehicleType, setSelectedVehicleType]}
              />

              <CustomInputSelection
                ref={carTypeRef}
                list={selectedVehicleType.list}
                selected={[selectedCarType, setSelectedCarType]}
              />

              <CustomInputSelection
                ref={carColorRef}
                list={carColorList}
                selected={[selectedCarColor, setSelectedCarColor]}
              />

              <InputText
                type="text"
                placeholder="ป้ายทะเบียน *"
                autoComplete="license-plate"
                Icon={FaCarRear}
                disabled={isLoading}
                onChange={onChangeLicensePlateInput}
                isError={licensePlateError}
                ref={licensePlateRef}
                value={licensePlate}
                required
              />
            </div>

            <div className="mt-6 flex-col  mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลบริการ</h2>

              <CustomInputSelection
                isOptional
                ref={serviceRef}
                list={serviceListMain}
                selected={[selectedService, setSelectedService]}
                keySpan={selectedCarType.id}
              />

              <AddServiceSelection
                addAble
                ref={addServiceRef}
                list={additionalListMain}
                setList={setAdditionalListMain}
                selected={[addSelectedService, setAddSelectedService]}
                selectedCarType={selectedCarType}
                selectedService={selectedService}
              />

              <CustomInputSelection
                isOptional
                ref={promotionRef}
                list={promotionListMain}
                selected={[selectedPromotion, setSelectedPromotion]}
                KeyDisabled={selectedService.pid}
              />

              <h2 className=" text-xs text-right font-bold ">
                รวม{" "}
                {totalPrice.total.toLocaleString("th-TH", {
                  style: "currency",
                  currency: "THB",
                })}
              </h2>
            </div>

            <div className="mt-6 flex-col max-w-sm mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลลูกค้า</h2>

              <UserAutoComplete
                placeholder="ไลน์ลูกค้า"
                ref={customerRef}
                users={displayCustomers}
                selected={[selectedCustomer, setSelectedCustomer]}
              />

              <InputText
                type="text"
                autoComplete="name"
                placeholder="ชื่อลูกค้า"
                Icon={FaUser}
                value={name}
                ref={nameRef}
                onChange={onChangeNameInput}
                disabled={isLoading}
                isError={nameError}
                required
              />

              <InputText
                type="tel"
                autoComplete="tel"
                pattern="(0)\d{8,9}"
                inputMode="numeric"
                placeholder="เบอร์โทร *"
                Icon={FaPhone}
                value={phoneNumber}
                onChange={onChangePhoneNumberInput}
                ref={phoneNumberRef}
                disabled={isLoading}
                isError={phoneNumberError}
                required
              />
            </div>

            <div className="justify-center flex mt-5">
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-mainred px-4 py-3 w-full text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:animate-pulse"
                type="submit"
                disabled={checkLoading("createBooking")}>
                {checkLoading("createBooking") ? "กำลังโหลด..." : "บันทึก"}
              </button>
            </div>

            <div className=" pt-3 ">
              <p className=" text-center text-gray-400 text-xs">
                ร้านเคเอสอาร์ คาร์คลีน
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
