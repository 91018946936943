import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

// components
import RootLayout from "./pages/app/Root";
import IndexApp from "./pages/app/App";
import bookingRoutes from "./routes/booking";
import pointRoutes from "./routes/point";
import shortlyRoutes from "./routes/shortly";
import workRoutes from "./routes/work";
import billRoutes from "./routes/bill";

const rootRoutes = [
  {
    path: "/",
    element: <IndexApp />,
  },
  {
    path: "booking/",
    children: bookingRoutes,
  },
  {
    path: "point/",
    children: pointRoutes,
  },
  {
    path: "work/",
    children: workRoutes,
  },
  {
    path: "bill/",
    children: billRoutes,
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      ...shortlyRoutes,
      {
        path: "/",
        element: <RootLayout />,
        children: [
          ...rootRoutes,
          { path: "*", element: <h1 className="min-h-screen">Not Found</h1> },
        ],
      },
    ],
  },
]);

const root = document.getElementById("root");
createRoot(root).render(<RouterProvider router={router} />);
reportWebVitals();
