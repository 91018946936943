/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, forwardRef, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { FaCheck, FaChevronDown } from "react-icons/fa6";

import { log } from "../../../utils/log";
import LineAvatar from "../../../components/LineAvatar";

const UserAutoComplete = forwardRef((props, ref) => {
  const { isOptional, isError, placeholder, users } = props;
  const [selected, setSelected] = props.selected;
  const [query, setQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const handleFilter = (query) => {
    setQuery(query);

    let filteredUsers = [];
    if (query === "") {
      filteredUsers = users.slice(0, 10);
    } else {
      filteredUsers = users
        .filter((user) =>
          user?.line?.displayName
            ?.toLowerCase()
            ?.replace(/\s+/g, "")
            ?.includes(query.toLowerCase().replace(/\s+/g, ""))
        )
        .slice(0, 10);
    }
    setFilteredUsers(filteredUsers);
  };

  const handleSelect = (user) => {
    if (user.uid === selected?.uid) {
      setSelected(null);
    } else {
      setSelected(user);
    }
  };

  useEffect(() => {
    if (users?.lenght > 0) {
      handleFilter("");
    }
  }, []);

  return (
    <Combobox value={selected} onChange={handleSelect}>
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          <Combobox.Input
            autoComplete="off"
            ref={ref}
            className={`${
              isOptional && selected?.uid === "none"
                ? "bg-gray-100 border-0 text-gray-500 placeholder-gray-500 focus:placeholder-gray-300 focus:ring-0 "
                : isError
                ? "bg-red-50 border-0 text-red-900 placeholder-red-700 focus:placeholder-red-300  focus:ring-0 "
                : "bg-gray-100 border-0 text-gray-900 placeholder-gray-500 focus:placeholder-gray-300 focus:ring-0 "
            } border shadow block w-full text-sm rounded  p-3`}
            placeholder={placeholder}
            displayValue={(user) => user?.line?.displayName}
            onChange={(event) => handleFilter(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <FaChevronDown
              className="h-4 w-4 text-gray-300"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterEnter={() => handleFilter("")}
          afterLeave={() => handleFilter("")}>
          <Combobox.Options className="absolute mt-1 z-30 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredUsers?.length === 0 ? (
              query === "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  กำลังโหลด...
                </div>
              ) : (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  ไม่พบผลลัพธ์
                </div>
              )
            ) : (
              filteredUsers?.map((user, idx) => (
                <Combobox.Option
                  key={user.uid}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active
                        ? "bg-gray-100 text-gray-900"
                        : isOptional && idx === 0
                        ? "text-gray-400"
                        : "text-gray-900"
                    }`
                  }
                  value={user}>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}>
                        <LineAvatar user={user} />
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                          <FaCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
});

export default UserAutoComplete;
