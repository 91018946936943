import { FaCaretLeft, FaCaretRight, FaPlus } from "react-icons/fa6";

function TableBottomNav({
  size,
  startDisplay,
  handlePrev,
  handleNext,
  handleAdd,
  maxDisplay = 5,
}) {
  return (
    <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between p-2 bg-gray-50 rounded-b-lg">
      <span className="text-sm font-normal text-gray-500  mb-0  inline w-auto">
        แสดง{" "}
        <span className="font-semibold text-gray-900">
          {size > 0 ? startDisplay + 1 : 0}-
          {startDisplay + maxDisplay > size ? size : startDisplay + maxDisplay}
        </span>{" "}
        จาก <span className="font-semibold text-gray-900">{size}</span>
      </span>
      <ul className="inline-flex -space-x-px text-sm h-8">
        <li>
          <button
            onClick={handlePrev}
            disabled={startDisplay === 0}
            className="flex items-center justify-center px-2 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 disabled:hover:bg-white disabled:hover:text-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed">
            <FaCaretLeft className="text-xl" />
          </button>
        </li>
        {handleAdd && (
          <li>
            <button
              onClick={handleAdd}
              disabled={!handleAdd}
              className="flex items-center justify-center px-2 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300  hover:bg-gray-100 hover:text-gray-700 disabled:hover:bg-white disabled:hover:text-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed">
              <FaPlus className="text-xl" />
            </button>
          </li>
        )}

        <li>
          <button
            onClick={handleNext}
            disabled={startDisplay + maxDisplay >= size}
            className="flex items-center justify-center px-2 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 disabled:hover:bg-white disabled:hover:text-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed">
            <FaCaretRight className="text-xl" />
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default TableBottomNav;
