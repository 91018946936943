/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import CircleBg from "./CircleBg";

function Header({ title, subTitle = "KSR Carclean", requireType, userType }) {
  const [textTitle, setTextTitle] = useState(title);

  const handleSetTextTitle = () => {
    if (requireType) {
      switch (requireType) {
        case "employee":
          if (userType === "employee") {
            setTextTitle(title);
          } else if (userType === "customer") {
            setTextTitle("เฉพาะพนักงานเท่านั้น");
          } else {
            setTextTitle("โปรดเข้าสู่ระบบเพื่อใช้งาน");
          }
          break;
        case "customer":
          if (userType === "customer") {
            setTextTitle(title);
          } else {
            setTextTitle("โปรดเข้าสู่ระบบเพื่อใช้งาน");
          }
          break;
        default:
          setTextTitle(title);
      }
    } else {
      setTextTitle(title);
    }
  };

  useEffect(() => {
    handleSetTextTitle();
  }, [requireType, userType, title]);

  return (
    <>
      <CircleBg />

      <div className=" absolute top-10 left-1/2 -translate-x-1/2 w-screen text-center ">
        <h1 className=" text-3xl font-bold text-white sm:text-5xl">
          {textTitle}
        </h1>
        <p className=" text-base sm:text-lg text-white mt-2 font-bold">
          {subTitle}
        </p>
      </div>
    </>
  );
}

export default Header;
