/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { findObj } from "../../utils/common";
import { carColorList, carTypeList } from "../../data/car";
import { serviceList, serviceMCList } from "../../data/service";
import { promotionList } from "../../data/promotion";
import { convertTimestampToDate } from "../../utils/datetime";
import { getBooking, updateBooking } from "../../database/booking";
import { RootContext } from "../app/Root";
import { FaCarSide } from "react-icons/fa6";
import { log } from "../../utils/log";
import { FaPhone } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
import BookingDetailTab from "./components/BookingDetailTab";
import { getUser } from "../../database/user";
import Header from "../../components/header/Header";

export default function BookingDetail() {
  let { bookingId } = useParams();

  const rootContext = useContext(RootContext);
  const { incLoading, decLoading } = rootContext.loading;
  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { openConfirm } = rootContext.confirm;

  //user, name, phone,
  const [bookingData, setBookingData] = useState(null);
  const [customerData, setCustomerData] = useState(null);

  const getCustomerData = async (uid) => {
    incLoading("getCustomerData");
    const resFindCustomer = await getUser(uid);
    decLoading("getCustomerData");

    if (!resFindCustomer.ok || !resFindCustomer.data) {
      return pushToast(`ไม่พบข้อมูลลูกค้า`, "danger");
    }

    setCustomerData(resFindCustomer.data);
  };

  const cancelBooking = async () => {
    incLoading("updateBooking");
    const resUpdateStatusBooking = await updateBooking(bookingId, {
      status: "canceled",
    });
    decLoading("updateBooking");

    if (!resUpdateStatusBooking.ok) {
      pushToast(`ไม่สามารถยกเลิกการจองคิวได้ ลองใหม่อีกครั้ง!`, "danger");
    } else {
      pushToast(`ยกเลิกการจองคิว #${bookingId} เรียบร้อยแล้ว`, "success");
      window.location.reload();
    }
  };

  const handleCancel = async () => {
    const resConfirm = await openConfirm(
      "ยกเลิกการจองคิว",
      `คุณต้องการยกเลิกการจองคิวนี้ ใช่หรือไม่?`
    );
    if (!resConfirm) return;

    cancelBooking();
  };

  const fetchBookingData = async () => {
    incLoading("fetchBooking");
    const resFindBooking = await getBooking(bookingId);
    decLoading("fetchBooking");

    if (!resFindBooking.ok || !resFindBooking.data) {
      return pushToast(`ไม่พบข้อมูลการจองคิว #${bookingId}`, "danger");
    }

    setBookingData(resFindBooking.data);
  };

  useEffect(() => {
    fetchBookingData();
  }, []);

  useEffect(() => {
    if (bookingData && bookingData?.customer?.uid) {
      getCustomerData(bookingData.customer.uid);
    }
  }, [bookingData]);

  return (
    <div className="mb-32">
      <Header title="ข้อมูลการจองคิว" />

      <div className="pt-36 pb-20">
        <div className=" w-full mt-2 max-w-[414px] bg-white mx-auto pt-1 ">
          <div className="max-w-sm relative w-[22rem] mx-auto bg-white border border-gray-200 overflow-hidden rounded-lg shadow">
            <BookingDetailTab
              bookingData={bookingData}
              userData={customerData}
              handleCancel={handleCancel}
            />
          </div>
        </div>

        <div className=" w-full max-w-[414px] mt-2 mx-auto pt-1">
          <div className="max-w-sm relative cursor-pointer bg-white border border-gray-200 px-4 w-[22rem] mx-auto   sm:px-4  rounded-lg shadow py-4">
            <a href="tel:0654068840">
              <h1 className="text-sm flex space-x-1 items-center">
                <FaPhone className="w-5 h-5 mr-2" />
                <span>โทรหาร้าน 0654068840</span>
              </h1>
            </a>
          </div>
        </div>

        <div className=" w-full max-w-[414px] mt-2 mx-auto pt-1">
          <div className="max-w-sm relative cursor-pointer   bg-white border border-gray-200  px-4 w-[22rem] mx-auto sm:px-4 rounded-lg shadow py-4">
            <a href="https://maps.app.goo.gl/wkCvCMn9MqZSGgc29">
              <h1 className="text-sm flex space-x-1 items-center">
                <FaMapMarkedAlt className="w-5 h-5 mr-2" />
                <span>นำทางไปยังร้าน สาขาโลตัสสงขลา</span>
              </h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
