import React from "react";
import { carTypeList, carColorList } from "../../../data/car";

export default function CarDetail({ car }) {
  const carVehicle = carTypeList.find((type) => type.id === car.vehicle);
  const carType = carVehicle.list.find((type) => type.id === car.type);
  const carColor = carColorList.find((color) => color.id === car.color);

  return (
    <div className="max-w-sm p-6 bg-white ">
      <div className="flex justify-center items-center w-full ">
        <carType.icon className="w-20 h-20 animate-pulse mb-3 hover:scale-110" />
      </div>
      <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
        ทะเบียน {car.plate}
      </h5>
      <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
        {carType.name.th + " " + carColor.name.th}
      </p>
    </div>
  );
}
