/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { FaCheckCircle } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";

const SelectMainService = forwardRef((props, ref) => {
  const { list, keySpan } = props;
  const [selected, setSelected] = props.selected;

  const scrollToItem = async (id) => {
    await new Promise((resolve) => {
      const check = () => {
        if (ref.current?.querySelector(`#${id}`)) {
          resolve();
        } else {
          setTimeout(check, 100);
        }
      };
      check();
    });

    if (selected && ref.current) {
      ref.current?.querySelector(`#${id}`)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  // scroll to item
  useEffect(() => {
    scrollToItem(selected.id);
  }, [selected, ref]);

  return (
    <div className=" overflow-y-scroll h-64 rounded">
      <div className="mx-auto w-full  ">
        <RadioGroup value={selected} onChange={setSelected} ref={ref}>
          <div className="space-y-2  ">
            {list.slice(1).map((item, idx) => (
              <RadioGroup.Option
                key={idx}
                id={item.id}
                value={item}
                className={({ active, checked }) =>
                  `${active ? "" : ""}
                  ${
                    checked
                      ? " border-red-600 bg-red-100 hover:bg-red-200 "
                      : " border-gray-200 bg-gray-100 hover:bg-gray-200 "
                  }
                    items-center justify-between p-5 border rounded cursor-pointer w-full  shadow`
                }>
                {({ active, checked }) => (
                  <>
                    <div className="flex items-start justify-between w-full ">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <div className="flex space-x-2 items-center mb-3">
                            {checked && (
                              <div className="shrink-0 text-red-600">
                                <FaCheckCircle className="h-3 w-3" />
                              </div>
                            )}
                            <div>
                              <RadioGroup.Label
                                as="h2"
                                className={` text-xs font-extrabold tracking-tight ${
                                  checked ? "text-red-600" : "text-gray-900"
                                }`}>
                                {item.name.th} ({item.price[keySpan]} บาท)
                              </RadioGroup.Label>
                              {item.describe && (
                                <span className=" text-gray-400 text-[0.5rem]">
                                  {item.describe}
                                </span>
                              )}
                            </div>
                          </div>

                          <RadioGroup.Description
                            as="ul"
                            className={` ${
                              checked ? "text-gray-600" : "text-gray-500"
                            } space-y-2 text-xs ml-4 `}>
                            {item.services.map((item, idx) => (
                              <li
                                key={idx}
                                className="flex space-x-2 items-center">
                                <GoDotFill className="flex-shrink-0 w-3.5 h-3.5 text-red-600" />
                                <span className="leading-tight">{item}</span>
                              </li>
                            ))}
                          </RadioGroup.Description>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
});

export default SelectMainService;
