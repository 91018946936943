import {
  wh,
  wheres,
  addDocument,
  realtimeGetAllDocumentByQuery,
} from "./database";
import { orderBy, Timestamp } from "firebase/firestore";

// --------- get realtime bill ---------
export const realtimeGetBills = (setBills, status = "all") => {
  let queries = [
    ...wheres(wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return realtimeGetAllDocumentByQuery(setBills, "bill", queries);
};

// --------- add bill ---------
export const addBill = async (
  billData,
  startDateTime,
  status = "InService",
  date = new Date()
) => {
  const newBillData = {
    ...billData,
    datetime: Timestamp.fromDate(date),
    start: Timestamp.fromDate(startDateTime),
    status,
  };

  return await addDocument("bill", newBillData);
};
