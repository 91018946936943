export default function TableOptionModal(props) {
  const [selected, setSelected] = props.selected;
  const { option, displayRow } = props;

  if (selected && option) {
    return (
      <>
        <div
          className="absolute inset-0 bg-black/5 rounded-lg z-10"
          onClick={() => setSelected(null)}
        />
        <div className="absolute z-30 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ">
          <div className="w-48 text-gray-900 bg-white shadow-md rounded-lg overflow-hidden ">
            <div className={`px-6 py-2 text-sm break-words whitespace-normal `}>
              {displayRow(option?.head, selected)}
            </div>
            <hr />
            {option?.list.map((option, index) => (
              <button
                key={index}
                type="button"
                className="relative inline-flex items-center w-full px-4 py-1 text-sm text-gray-900 border-b border-gray-200 hover:bg-red-100 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={option.onDisabled && option.onDisabled(selected)}
                onClick={async () => {
                  await option.onClick(selected);
                  setSelected(null);
                }}>
                <option.icon className="w-3 h-3 me-2.5" />
                {option.text}
              </button>
            ))}
          </div>
        </div>
      </>
    );
  }
}
