import { orderBy } from "firebase/firestore";
import { wh, wheres, realtimeGetAllDocumentByQuery } from "./database";

// --------- get realtime users ---------
export const realtimeGetWorksByUid = (setWorks, uid, status = "all") => {
  let queries = [
    ...wheres(wh("uid", "==", uid), wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return realtimeGetAllDocumentByQuery(setWorks, "work", queries);
};

export const realtimeGetWorks = (setWorks, status = "all") => {
  let queries = [
    ...wheres(wh("status", "==", status)),
    orderBy("datetime", "desc"),
  ];
  return realtimeGetAllDocumentByQuery(setWorks, "work", queries);
};
