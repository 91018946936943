import React from "react";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useContext } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdDangerous } from "react-icons/md";
import { RootContext } from "../../pages/app/Root";

export default function Confirm() {
  const rootContext = useContext(RootContext);
  const { confirm, closeConfirm } = rootContext.confirm;

  return (
    <Transition appear show={confirm.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeConfirm(null)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 flex space-x-2 items-center  text-gray-900">
                  <span>{confirm.title}</span>
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 ">{confirm.body}</p>
                </div>

                <div className="mt-4 flex justify-center">
                  <button
                    type="button"
                    className="inline-flex mx-auto justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    onClick={() => {
                      closeConfirm(false);
                    }}>
                    {confirm.btnLeft}
                  </button>
                  <button
                    type="button"
                    className="inline-flex mx-auto justify-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 px-8"
                    onClick={() => {
                      closeConfirm(true);
                    }}>
                    {confirm.btnRight}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
