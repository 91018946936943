const isDevelopment = process.env.NODE_ENV === "development";
const isLogTime = process.env.REACT_APP_LOG_TIME === "true";
const isLogInfo = process.env.REACT_APP_LOG_INFO === "true";
const isLogAll = process.env.REACT_APP_LOG_ALL === "true";

export const log = (...messages) => {
  if (isDevelopment && isLogAll) {
    console.log(...messages);
  }
};

export const info = (...messages) => {
  if (isDevelopment && isLogInfo) {
    console.info(...messages);
  }
};

export const warn = (...messages) => {
  if (isDevelopment) {
    console.warn(...messages);
  }
};

export const error = (...messages) => {
  if (isDevelopment) {
    console.error(...messages);
  }
};

export const assert = (condition, message) => {
  if (isDevelopment) {
    console.assert(condition, message);
  }
};

export const debug = (...messages) => {
  if (isDevelopment) {
    console.debug(...messages);
  }
};

export const timeStart = (label) => {
  if (isDevelopment && isLogTime) {
    console.time(label);
  }
};

export const timeEnd = (label) => {
  if (isDevelopment && isLogTime) {
    console.timeEnd(label);
  }
};
