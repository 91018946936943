import { forwardRef } from "react";

const InputText = forwardRef((props, ref) => {
  const {
    Icon,
    isError,
    onChange,
    disabled,
    required,
    value,
    autoComplete,
    placeholder,
    pattern,
    inputMode,
    type = "text",
  } = props;

  return (
    <div className="relative">
      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
        <Icon
          className={`${isError ? "text-red-600" : "text-gray-500"} w-4 h-4 `}
        />
      </div>
      <input
        type={type}
        autoComplete={autoComplete}
        className={`${
          isError
            ? "bg-red-50 border-0 text-red-900 placeholder-red-700 focus:placeholder-red-300 focus:ring-0 "
            : "bg-gray-100 border-0 text-gray-900 placeholder-gray-500 focus:placeholder-gray-300 focus:ring-0 "
        } border shadow block w-full text-sm rounded ps-10 p-3`}
        placeholder={placeholder}
        value={value}
        ref={ref}
        onChange={onChange}
        disabled={disabled}
        required={required}
        pattern={pattern}
        inputMode={inputMode}
      />
    </div>
  );
});

export default InputText;
