import AddPoint from "../pages/point/AddPoint";
import AllPoint from "../pages/point/AllPoint";
import Point from "../pages/point/Point";
import Redeem from "../pages/point/Redeem";

const pointRoutes = [
  {
    path: "",
    element: <Point />,
  },
  {
    path: ":userId",
    element: <Point />,
  },
  {
    path: "add",
    element: <AddPoint />,
  },
  {
    path: "all",
    element: <AllPoint />,
  },
  {
    path: "redeem",
    element: <Redeem />,
  },
];

export default pointRoutes;
