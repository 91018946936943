import "datejs";

export const getNMonthList = (numberOfPastMonths, numberOfFutureMonths) => {
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const currentDate = new Date().clearTime();
  currentDate.addMonths(-numberOfPastMonths);

  const months = [];
  for (let i = 0; i < numberOfPastMonths + numberOfFutureMonths + 1; i++) {
    const startDate = new Date()
      .clearTime()
      .addMonths(i - numberOfPastMonths)
      .moveToFirstDayOfMonth();

    const endDate = startDate.clone().moveToLastDayOfMonth();
    const formattedStartDate = `${thaiMonths[startDate.getMonth()]} ${
      startDate.getFullYear() + 543
    }`;

    months.push({
      name: formattedStartDate,
      value: startDate.clone(),
      startDate: startDate.clone(),
      endDate: endDate.clone(),
    });
  }

  return months;
};
