/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { RootContext } from "../app/Root";
import { realtimeGetBills } from "../../database/bill";
import ServiceDetail from "../../components/ServiceDetail";

export default function BillDashboard() {
  const rootContext = useContext(RootContext);

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { userData, isLogin } = rootContext.user;
  const { isLoading, incLoading, decLoading, checkLoading } =
    rootContext.loading;

  const [bills, setBills] = useState(null);
  const [displayBills, setDisplayBills] = useState([]);

  const calculateTotalPrice = (service, carType) => {
    let total = 0;
    let discount = 0;

    if (service.main.id !== "none") {
      total += service.main.price[carType];
    }

    service?.additional.forEach((service) => {
      total += service.price[carType];
    });

    if (service.promotion.id !== "none") {
      if (service.promotion.type === "discount") {
        if (service.promotion.discount.type === "price") {
          discount -= service.promotion.discount.value;
        } else if (service.promotion.discount.type === "percent") {
          discount -= total * (service.promotion.discount.value / 100);
        }
      }
    }

    return {
      total,
      discount,
      net: total + discount,
    };
  };

  useEffect(() => {
    if (isLogin && userData?.type === "employee") {
      incLoading("realtimeGetBills");
      realtimeGetBills(setBills, "all");
    }
  }, [isLogin]);

  useEffect(() => {
    if (bills) {
      setDisplayBills(bills);
      decLoading("realtimeGetBills");
    }
  }, [bills]);

  return (
    <div className="grid grid-cols-4 gap-4 min-h-screen p-5 mb-20 ">
      {displayBills.map((bill, idx) => (
        <div key={idx} className=" border h-fit rounded-lg w-72 shadow-md">
          <ServiceDetail
            mainService={bill.service.main}
            additionalService={bill.service.additional}
            promotion={bill.service.promotion}
            carType={bill.car.type}
            totalPrice={calculateTotalPrice(bill.service, bill.car.type)}
          />
        </div>
      ))}
    </div>
  );
}
