import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import { FaLongArrowAltDown } from "react-icons/fa";
import { IoReloadOutline } from "react-icons/io5";
import TableOptionModal from "./TableOptionModal";
import TableBottomNav from "./TableBottomNav";
import LineAvatar from "../LineAvatar";

// utils
import { getData } from "../../utils/common";
import { RootContext } from "../../pages/app/Root";
import { convertTimestampToDate } from "../../utils/datetime";

export default function Table({
  data,
  config,
  option,
  pathAdd,
  maxDisplay = 5,
}) {
  const navigate = useNavigate();
  const rootContext = useContext(RootContext);
  const { isLoading } = rootContext.loading;
  const { setCacheUsers } = rootContext.cache;

  const [startDisplay, setStartDisplay] = useState(0);
  const [display, setDisplay] = useState(data);
  const [selected, setSelected] = useState(null);
  const [sortDesc, setSortDesc] = useState(null);
  const [idxSort, setIdxSort] = useState(-1);

  useEffect(() => {
    setDisplay(data.slice(startDisplay, startDisplay + maxDisplay));
  }, [data, startDisplay, maxDisplay]);

  const handleAdd = () => {
    navigate(pathAdd);
  };

  const handleNext = () => {
    if (startDisplay + maxDisplay >= data.length) return;
    setStartDisplay((prev) => prev + maxDisplay);
  };

  const handlePrev = () => {
    if (startDisplay - maxDisplay < 0) return;
    setStartDisplay((prev) => prev - maxDisplay);
  };

  const handleSort = (key, idx) => {
    const sorted = data.sort((a, b) => {
      if (sortDesc) {
        return getData(a, key) > getData(b, key) ? 1 : -1;
      } else {
        return getData(a, key) < getData(b, key) ? 1 : -1;
      }
    });
    setSortDesc((prev) => !prev);
    setDisplay(sorted.slice(startDisplay, startDisplay + maxDisplay));
    setIdxSort(idx);
  };

  const displayRow = (data, item) => {
    switch (data.type) {
      case "uidAvatar":
        return (
          <LineAvatar
            uid={getData(item, data.data)}
            setCacheUsers={setCacheUsers}
          />
        );
      case "avatar":
        return <LineAvatar user={getData(item, data.data)} />;
      case "datetime":
        return convertTimestampToDate(getData(item, data.data)).toLocaleString(
          "th-TH"
        );
      case "date":
        return convertTimestampToDate(
          getData(item, data.data)
        ).toLocaleDateString("th-TH");
      case "time":
        return convertTimestampToDate(
          getData(item, data.data)
        ).toLocaleTimeString("th-TH");
      case "list":
        return getData(item, data.data)
          .flatMap((i) => getData(i, data.inner))
          .join(", ");
      case "label":
        return `${data.label}: ${getData(item, data.data)}`;
      case "number":
        return getData(item, data.data)?.toLocaleString("th-TH");
      case "customer":
        return `${getData(item, data.data)?.name} (${
          getData(item, data.data)?.phone
        })`;
      case "discount":
        const type = getData(item, data.data)?.type === "percent" ? "%" : "บาท";
        return (
          getData(item, data.data) &&
          `${getData(item, data.data)?.value} ${type}`
        );
      default:
        return getData(item, data.data);
    }
  };

  const alignText = (config, idx) => {
    const { align } = config[idx];

    switch (align) {
      case "left":
        return " text-left ";
      case "center":
        return " text-center ";
      case "right":
        return " text-right ";
      default:
        return idx === 0 ? " text-left " : " text-right ";
    }
  };

  return (
    <div className="relative">
      <TableOptionModal
        selected={[selected, setSelected]}
        option={option}
        displayRow={displayRow}
      />

      <div className="overflow-x-scroll rounded-t-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-b">
            <tr>
              {config.map((op, idx) => (
                <th
                  key={idx}
                  scope="col"
                  className={`px-6 py-3  font-medium text-gray-900 whitespace-nowrap ${alignText(
                    config,
                    idx
                  )} ${op.sortAble && "cursor-pointer hover:text-gray-500"}`}
                  onClick={() => op.sortAble && handleSort(op.data, idx)}>
                  <div className=" flex space-x-0.5 items-center">
                    {sortDesc !== null && op?.sortAble && idxSort === idx && (
                      <FaLongArrowAltDown
                        className={`inline w-3 h-3  animate-pulse ${
                          sortDesc && "transform rotate-180"
                        }`}
                      />
                    )}
                    <p>{op.head}</p>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="border-t">
            {isLoading
              ? display.length === 0 && (
                  <tr className={`bg-white border-b `}>
                    <td
                      colSpan={config.length}
                      className={`px-6 py-4 whitespace-nowrap flex space-x-2 items-center animate-pulse`}>
                      <IoReloadOutline className="animate-spin" />
                      <p className=" ">กำลังโหลดข้อมูล...</p>
                    </td>
                  </tr>
                )
              : display.length === 0 && (
                  <tr className={`bg-white border-b `}>
                    <td
                      colSpan={config.length}
                      className={`px-6 py-4 whitespace-nowrap flex space-x-2 items-center`}>
                      <p className=" ">ไม่มีข้อมูล</p>
                    </td>
                  </tr>
                )}
            {display.map((item, idx) => (
              <tr
                key={idx}
                className={`bg-white border-b ${
                  option && " cursor-pointer hover:bg-red-50 "
                }`}
                onClick={() => setSelected(item)}>
                {config.map((op, idx) => (
                  <td
                    key={idx}
                    className={`px-6 py-4 whitespace-nowrap ${alignText(
                      config,
                      idx
                    )}`}>
                    {displayRow(op, item)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <TableBottomNav
        size={data.length}
        startDisplay={startDisplay}
        handlePrev={handlePrev}
        handleNext={handleNext}
        handleAdd={pathAdd && handleAdd}
        maxDisplay={maxDisplay}
      />
    </div>
  );
}
