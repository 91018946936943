const promotionList = [
  {
    id: "monthlybirthday",
    pids: ["p1", "p3", "p5", "p7"],
    name: {
      th: "เดือนเกิดรับ gift voucher 200 บาท",
      en: "Birthday Month Get 200 Baht Gift Voucher",
    },
    type: "giftvoucher",
    giftvoucher: {
      value: 200,
      days: 30,
    },
  },
  {
    id: "50likeandshare",
    pids: ["p1", "p3", "p5", "p7"],
    name: {
      th: "ไลค์เพจและแชร์โพส ลด 50 บาท",
      en: "Like Page and Share Post Get 50 Baht Discount",
    },
    type: "discount",
    discount: {
      type: "price",
      value: 50,
    },
  },
  // {
  //   id: "reviewget50",
  //   pids: ["p1", "p3", "p5", "p7"],
  //   name: {
  //     th: "รีวิวร้านรับ gift voucher 50 บาท",
  //     en: "Review Shop Get 50 Baht Gift Voucher",
  //   },
  //   type: "giftvoucher",
  //   giftvoucher: {
  //     value: 50,
  //     days: 30,
  //   },
  // },
  // {
  //   id: "rainrepellent",
  //   pids: ["p3", "p5", "p7"],
  //   name: {
  //     th: "โปรโมชั่นเคลือบกระจกกันฝนฟรี",
  //     en: "Rain Repellent Coating Free",
  //   },
  //   type: "coupon",
  //   coupon: {
  //     type: "service",
  //     value: "เคลือบกระจกกันฝน",
  //   },
  // },
  {
    id: "ozonefree",
    pids: ["p3", "p5", "p7"],
    name: {
      th: "โปรโมชั่นอบโอโซนฆ่าเชื้อดับกลิ่นฟรี",
      en: "Ozone Sterilization Free",
    },
    type: "coupon",
    coupon: {
      type: "service",
      value: "อบโอโซนฆ่าเชื้อดับกลิ่น",
    },
  },
  {
    id: "1000fabriccleaning",
    pids: ["p5"],
    name: {
      th: "ฟอกเบาะลด 1,000 บาท",
      en: "1,000 Fabric Cleaning Discount",
    },
    type: "discount",
    discount: {
      type: "price",
      value: 1000,
    },
  },
  {
    id: "10000carcoating",
    pids: ["p3"],
    name: {
      th: "เคลือบแก้วลด 10,000 (3คันแรก)",
      en: "10,000 Car Coating Discount (First 3 Cars)",
    },
    type: "discount",
    discount: {
      type: "price",
      value: 10000,
    },
  },
  {
    id: "1000mccoating",
    pids: ["p4"],
    name: {
      th: "เคลือบแก้วมอไซค์ลด 1,000 (3คันแรก)",
      en: "1,000 Car Coating Discount (First 3 Cars)",
    },
    type: "discount",
    discount: {
      type: "price",
      value: 1000,
    },
  },
];

const userPromotion = [
  {
    id: "ozonefree",
    pids: ["p1", "p3", "p5", "p7"],
    name: {
      th: "โปรโมชั่นอบโอโซนฆ่าเชื้อดับกลิ่นฟรี",
      en: "Ozone Sterilization Free",
    },
    type: "coupon",
    coupon: {
      type: "service",
      value: "อบโอโซนฆ่าเชื้อดับกลิ่น",
    },
  },
  {
    id: "1000fabriccleaning",
    pids: ["p5"],
    name: {
      th: "ฟอกเบาะลด 1,000 บาท",
      en: "1,000 Fabric Cleaning Discount",
    },
    type: "discount",
    discount: {
      type: "price",
      value: 1000,
    },
  },
  {
    id: "10000carcoating",
    pids: ["p3"],
    name: {
      th: "เคลือบแก้วลด 10,000 (3คันแรก)",
      en: "10,000 Car Coating Discount (First 3 Cars)",
    },
    type: "discount",
    discount: {
      type: "price",
      value: 10000,
    },
  },
  {
    id: "1000mccoating",
    pids: ["p4"],
    name: {
      th: "เคลือบแก้วมอไซค์ลด 1,000 (3คันแรก)",
      en: "1,000 Car Coating Discount (First 3 Cars)",
    },
    type: "discount",
    discount: {
      type: "price",
      value: 1000,
    },
  },
];

export { promotionList, userPromotion };
