import { greetingList } from "../data/greeting";

export const findObj = (dataList, value, keyFind = "value") => {
  const foundType = dataList.find((dataItem) => dataItem[keyFind] === value);
  return foundType || {};
};

export const convertValue = (
  data,
  title,
  keyFind = "title",
  keyReturn = "value"
) => {
  const foundType = data.find((type) => type[keyFind] === title);
  return foundType ? foundType[keyReturn] : "";
};

export const convertTitle = (
  data,
  value,
  keyFind = "value",
  keyReturn = "title"
) => {
  const foundType = data.find((type) => type[keyFind] === value);
  return foundType ? foundType[keyReturn] : "";
};

export const convertObj = (data, value, keyReturn, keyFind = "value") => {
  const foundType = data.find((type) => type[keyFind] === value);
  return foundType ? foundType[keyReturn] : "";
};

export const randomGreeting = () => {
  return greetingList[Math.floor(Math.random() * greetingList.length)];
};

export const queryString2Obj = (queryString) => {
  const searchParams = new URLSearchParams(queryString);
  const obj = {};
  for (const [key, value] of searchParams) {
    obj[key] = value;
  }
  return obj;
};

export function thaiToEnglish(thaiString) {
  var thaiToEnglishMap = {
    ก: "k",
    ข: "kh",
    ฃ: "kh",
    ค: "kh",
    ฅ: "kh",
    ฆ: "kh",
    ง: "ng",
    จ: "ch",
    ฉ: "ch",
    ช: "ch",
    ซ: "s",
    ฌ: "ch",
    ญ: "y",
    ฎ: "d",
    ฏ: "t",
    ฐ: "th",
    ฑ: "th",
    ฒ: "th",
    ณ: "n",
    ด: "d",
    ต: "dt",
    ถ: "th",
    ท: "th",
    ธ: "th",
    น: "n",
    บ: "b",
    ป: "bp",
    ผ: "ph",
    ฝ: "f",
    พ: "ph",
    ฟ: "f",
    ภ: "ph",
    ม: "m",
    ย: "y",
    ร: "r",
    ล: "l",
    ว: "w",
    ศ: "s",
    ษ: "s",
    ส: "s",
    ห: "h",
    ฬ: "l",
    อ: "",
    ฮ: "h",
    ๆ: "",
    ะ: "a",
    "ั": "a",
    า: "a",
    ำ: "am",
    "ิ": "i",
    "ี": "i",
    "ึ": "ue",
    "ื": "ue",
    "ุ": "u",
    "ู": "u",
    เ: "e",
    แ: "ae",
    โ: "o",
    ใ: "ai",
    ไ: "ai",
    "่": "",
    "้": "h",
    "๊": "",
    "๋": "",
    "์": "n",
    "ํ": "am",
    "๐": "0",
    "๑": "1",
    "๒": "2",
    "๓": "3",
    "๔": "4",
    "๕": "5",
    "๖": "6",
    "๗": "7",
    "๘": "8",
    "๙": "9",
  };

  var englishString = "";
  for (var i = 0; i < thaiString.length; i++) {
    var thaiChar = thaiString.charAt(i);
    var englishChar = thaiToEnglishMap[thaiChar];
    // If there's no mapping, keep the original character
    englishString += englishChar || thaiChar;
  }

  return englishString;
}

export function getData(data, nestedProperty) {
  const properties = nestedProperty?.split(".");
  if (!properties) return data;
  let result = data;

  for (let property of properties) {
    if (result && result.hasOwnProperty(property)) {
      result = result[property];
    } else {
      return undefined; // Property not found
    }
  }

  return result;
}
