import {
  doc,
  query,
  where,
  setDoc,
  getDoc,
  addDoc,
  getDocs,
  updateDoc,
  Timestamp,
  onSnapshot,
  collection,
} from "firebase/firestore";
import { db } from "./firebase";
import { debug, info, warn, error } from "../utils/log";

const IsLogDB = process.env.REACT_APP_LOG_DATABASE === "true";

// --------- queries ---------
export const wh = (field, operator, value, type = "string") => {
  return { field, operator, value, type };
};

export const wheres = (...queries) => {
  let result = [];
  for (let query of queries) {
    const { field, operator, value, type } = query;
    switch (type) {
      case "date":
        result.push(where(field, operator, Timestamp.fromDate(value)));
        break;
      default:
        if (value !== "all") {
          result.push(where(field, operator, value));
        }
    }
  }

  return result;
};

// --------- get realtime data ---------
export const realtimeGetDocumentById = (coll, docId, setData) => {
  if (IsLogDB) debug(`realtime getting document in "${coll}" by id "${docId}"`);

  try {
    const docRef = doc(db, coll, docId);
    onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = {
          ...doc.data(),
          id: docId,
        };

        setData(data);
        if (IsLogDB) info("got data:", data);
      } else {
        if (IsLogDB)
          warn(
            `realtime getting document in "${coll}" by id "${docId}" not found`
          );
      }
    });
  } catch (err) {
    if (IsLogDB)
      error(
        `error realtime getting document in "${coll}" by id "${docId}"`,
        err
      );
  }
};

export const realtimeGetAllDocumentByQuery = (setData, coll, queries) => {
  if (IsLogDB) debug(`realtime finding to "${coll}" by query`);
  const q = query(collection(db, coll), ...queries);

  try {
    onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      setData(data);
      if (IsLogDB) info("got data:", data);
    });
  } catch (err) {
    if (IsLogDB) error(`error realtime finding to "${coll}" by query`, err);
  }
};

// --------- got data ---------
export const getDocumentById = async (coll, docId) => {
  if (IsLogDB) debug(`getting document from "${coll}" by id "${docId}"`);
  let response = {
    ok: true,
    data: null,
  };

  try {
    const docSnap = await getDoc(doc(db, coll, docId));
    if (docSnap.exists()) {
      const data = {
        ...docSnap.data(),
        id: docId,
      };

      response.data = data;
      if (IsLogDB) info("got data:", data);
    }
  } catch (err) {
    response.ok = false;
    if (IsLogDB)
      error(`error getting document from "${coll}" by id "${docId}"`, err);
  }

  return response;
};

export const getAllDocumentByQuery = async (coll, queries = []) => {
  if (IsLogDB) debug(`finding in "${coll}" by query`);
  const q = query(collection(db, coll), ...queries);

  let response = {
    ok: true,
    data: null,
  };

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      let data = [];
      querySnapshot.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      response.data = data;
      if (IsLogDB) info("got data:", data);
    }
  } catch (err) {
    response.ok = false;
    if (IsLogDB) error(`error finding in "${coll}" by query`, err);
  }

  return response;
};

// --------- add and update data ---------
export const setDocument = async (coll, docId, docData) => {
  if (IsLogDB) debug(`setting document in "${coll}" at "${docId}"`);
  let response = {
    ok: true,
    data: null,
  };

  try {
    await setDoc(doc(db, coll, docId), docData);
    const data = {
      ...docData,
      id: docId,
    };

    response.data = data;
    if (IsLogDB) info("set data:", data);
  } catch (err) {
    response.ok = false;
    if (IsLogDB)
      error(`error setting document in "${coll}" at "${docId}"`, err);
  }

  return response;
};

export const addDocument = async (coll, docData) => {
  if (IsLogDB) debug(`adding document to "${coll}"`);
  let response = {
    ok: true,
    data: null,
  };

  try {
    const docRef = await addDoc(collection(db, coll), docData);
    const data = {
      id: docRef.id,
      ...docData,
    };

    response.data = data;
    if (IsLogDB) info("add data:", data);
  } catch (err) {
    response.ok = false;
    if (IsLogDB) error(`error adding document to "${coll}"`, err);
  }

  return response;
};

export const updateDocument = async (coll, docId, docData) => {
  if (IsLogDB) debug(`updating document in "${coll}" at "${docId}"`);
  let response = {
    ok: true,
    data: null,
  };

  try {
    await updateDoc(doc(db, coll, docId), docData);
    const data = {
      ...docData,
      id: docId,
    };

    response.data = data;
    if (IsLogDB) info("update data:", data);
  } catch (err) {
    response.ok = false;
    if (IsLogDB)
      error(`error updating document in "${coll}" at "${docId}"`, err);
  }

  return response;
};

// --------- count data ---------
export const countDocumentByQuery = async (coll, queries = []) => {
  if (IsLogDB) debug(`counting in "${coll}" by query`);
  const q = query(collection(db, coll), ...queries);

  let response = {
    ok: true,
    data: 0,
  };

  try {
    const querySnapshot = await getDocs(q);
    response.data = querySnapshot.size;
    if (IsLogDB) info("count data:", response.data);
  } catch (err) {
    response.ok = false;
    if (IsLogDB) error(`error counting in "${coll}" by query`, err);
  }

  return response;
};
