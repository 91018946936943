import React from "react";
import { FaArrowRight } from "react-icons/fa";
import CircleBg from "./CircleBg";

export default function HeadLanding() {
  const promotions = [
    {
      image: "/images/promotion/month1.png",
      title: "โปรโมชั่นประจำเดือนมกราคม",
      subtitle:
        "เลือกใช้บริการล้างรถแพ็คเกจใดก็ได้รับทันทีรายการอบโอโซน ฆ่าเชื้อ ดับกลิ่นฟรี",
      note: "ถึง 31 มกราคม",
      href: "/booking",
    },
    {
      image: "/images/promotion/3firstcoating.png",
      title: "โปรโมชั่นเคลือบแก้วประจำเดือน",
      subtitle:
        "เฉพาะลูกค้า 3 ท่านแรกของเดือน รับส่วนลดบริการเคลือบแก้วเซรามิก แกรไฟต์ จาก 16,990 จ่ายเพียง 7,990 บาท",
      note: "*จำนวนจำกัด 3 ท่าน",
      href: "/booking",
    },
  ];

  return (
    <div className=" relative h-fit z-20">
      <CircleBg />

      <div className=" absolute top-10 left-1/2 -translate-x-1/2 w-screen text-center ">
        <h1 className=" text-3xl font-bold text-white sm:text-5xl">
          ร้านเคเอสอาร์ คาร์คลีน
        </h1>
        <p className=" text-base sm:text-lg text-white mt-2">
          ศูนย์ดูแลความงามรถยนต์ครบวงจร
        </p>
      </div>

      <div className="relative w-full -z-10  min-h-screen ">
        <div className="absolute inset-0 bg-white z-10" />
      </div>

      <div className="absolute top-36 ">
        <div className=" w-screen flex flex-col sm:flex-row sm:px-60 space-y-4 sm:space-y-0 justify-center">
          {promotions.map((item, idx) => (
            <div key={idx} className="w-[22rem] sm:w-96 z-20 mx-auto px-4">
              <div className="max-w-sm bg-white border sm:px-4 border-gray-200 rounded-lg shadow">
                <div className="">
                  <figure className=" w-56 mx-auto my-4">
                    <img
                      className="h-auto max-w-full rounded-lg"
                      src={item.image}
                      alt=""
                    />
                  </figure>
                </div>
                <div className="p-5">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {item.title}
                  </h5>
                  <p className="mb-3 font-normal text-sm text-gray-700 dark:text-gray-400">
                    {item.subtitle}{" "}
                    <span className="text-red-500">{item.note}</span>
                  </p>
                  <a
                    href={item.href}
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                    จองคิวรับโปรโมชั่น
                    <FaArrowRight className=" w-3.5 h-3.5 ms-2" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
