/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect, useContext } from "react";
import { Transition } from "@headlessui/react";

import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import { RootContext } from "../../pages/app/Root";

export default function MultiToast() {
  const context = useContext(RootContext);
  const { toasts } = context.toast;

  return (
    <Transition appear show={toasts.length > 0} as={Fragment}>
      <div>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="-translate-y-100"
          enterTo="translate-y-0"
          leave="ease-in duration-1000 "
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90">
          <div
            className={`fixed grid gap-1 z-50 mt-2 transform inset-0 overflow-y-auto w-96 h-fit left-1/2 -translate-x-1/2 top-4 `}>
            {toasts.map((toast, idx) => (
              <Toast key={idx} toast={toast} />
            ))}
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}

function Toast({ toast }) {
  const [isOpen, setIsOpen] = useState(true);

  const getColor = (type = toast.type) => {
    if (toast.color) {
      return toast.color;
    }

    switch (type) {
      case "correct":
        return "text-green-500 bg-green-100";
      case "danger":
        return "text-red-500 bg-red-100";
      case "warn":
        return "text-orange-500 bg-orange-100";
      case "info":
        return "text-blue-500 bg-blue-100";
      default:
        return "text-blue-500 bg-blue-100";
    }
  };

  const getIcon = (type = toast.type) => {
    if (toast.icon) {
      return <toast.icon className="w-5 h-5" />;
    }

    switch (type) {
      case "correct":
        return <FaCheckCircle className="w-5 h-5" />;
      case "danger":
        return <IoClose className="w-5 h-5" />;
      case "warn":
        return <RiErrorWarningFill className="w-5 h-5" />;
      case "info":
        return <FaInfoCircle className="w-5 h-5" />;
      default:
        return <FaInfoCircle className="w-5 h-5" />;
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, toast.time);
    }
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="flex min-h-full items-start justify-center text-center mx-1  ">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="-translate-y-100 scale-90"
          enterTo="translate-y-0 scale-100"
          leave="ease-in duration-500 "
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90">
          <div className="flex items-center shadow-md w-full max-w-sm px-4 py-2 text-gray-500 bg-white rounded-lg border border-gray-100 ">
            <div
              className={` inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg ${getColor()}`}>
              {getIcon()}
            </div>

            <div className="ms-3 text-sm font-normal">{toast.text}</div>

            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-0 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 ">
              <IoClose className="w-5 h-5" />
            </button>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}
