/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

// components
import { FaBars, FaXmark } from "react-icons/fa6";
import { SiFastapi } from "react-icons/si";
import LineAvatar from "../LineAvatar";
import LineImg from "../../images/LineImg";
import KSRImg from "../../images/KSRImg";

// database
import {
  userNavList,
  customerNavList,
  employeeNavList,
} from "../../data/navigation";

// utils
import { log } from "../../utils/log";
import { RootContext } from "../../pages/app/Root";

const TopButton = ({
  isLogin,
  checkLoading,
  isRequireLogin,
  userData,
  login,
  navigate,
}) => {
  if (checkLoading("fetchUser", "lineLogin")) {
    return (
      <button
        disabled
        className="px-3 py-2 text-sm font-medium text-[#000] bg-white rounded-lg focus:ring-0  focus:outline-none hover:scale-110 transform duration-150 whitespace-nowrap flex justify-center items-center space-x-1 disabled:bg-gray-200 w-32 h-10 ">
        <LineImg color="#00B900" className="w-6 h-6 mr-1  " />
        <p className=" text-black pr-1">กำลังโหลด...</p>
      </button>
    );
  } else {
    if (isLogin) {
      return (
        <button
          type="button"
          className="px-3 py-2 text-sm font-medium text-[#000] bg-white rounded-lg hover:bg-gray-100 focus:ring-0  focus:outline-none hover:scale-110 transform duration-150 whitespace-nowrap flex justify-center items-center space-x-1 disabled:bg-gray-200 w-32 h-10 ">
          <LineAvatar user={userData} />
        </button>
      );
    } else {
      if (isRequireLogin) {
        return (
          <button
            type="button"
            className="px-3 py-2 text-sm font-medium text-[#000] bg-white hover:bg-gray-100 rounded-lg focus:ring-0  focus:outline-none hover:scale-110 transform duration-150 whitespace-nowrap flex justify-center items-center space-x-1 disabled:bg-gray-200 w-32 h-10 "
            onClick={() => login(true)}>
            <LineImg color="#00B900" className="w-6 h-6 mr-1" />
            <p className=" text-black pr-1">ลงชื่อเข้าใช้</p>
          </button>
        );
      } else {
        return (
          <button
            type="button"
            className="px-3 py-2 text-sm font-medium text-[#000] bg-white rounded-lg hover:bg-gray-100 focus:ring-0  focus:outline-none hover:scale-110 transform duration-150 whitespace-nowrap flex justify-center items-center space-x-1 disabled:bg-gray-200 w-32 h-10 "
            onClick={() => navigate("/booking")}>
            <SiFastapi color="#E50914" className="w-6 h-6 mr-1" />
            <p>จองคิวด่วน</p>
          </button>
        );
      }
    }
  }
};

export default function NavBar({ login }) {
  const location = useLocation();
  const navigate = useNavigate();

  const rootContext = useContext(RootContext);
  const { isLoading, checkLoading } = rootContext.loading;
  const { isLogin, isRequireLogin, userData } = rootContext.user;

  const [navList, setNavList] = useState(userNavList);

  useEffect(() => {
    if (isLogin) {
      if (userData?.type === "employee") {
        setNavList(employeeNavList);
      } else {
        setNavList(customerNavList);
      }
    }
  }, [isLogin]);

  const isMatchPage = (idx, path) => {
    if (idx === 0) {
      return location.pathname === path.href;
    } else {
      return location.pathname?.startsWith(path.href);
    }
  };

  return (
    <Disclosure as="nav" className=" bg-mainred w-screen fixed z-50">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                {/* Mobile Menu & Logo */}
                <div className="flex-shrink-0 flex">
                  <div className="-mr-2 flex md:hidden h-fit my-auto">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-100 p-2 text-red-800 hover:bg-gray-300 hover:text-red-600 focus:outline-none focus:ring-0 focus:ring-white ">
                      {open ? (
                        <FaXmark className="block h-6 w-6" />
                      ) : (
                        <FaBars className="block h-6 w-6" />
                      )}
                    </Disclosure.Button>
                  </div>

                  <button
                    disabled={isLoading}
                    onClick={() => {
                      log("reload");
                      window.location.replace("/");
                      window.location.reload();
                    }}
                    className="w-[100px] h-[100px]  hover:scale-110 transform duration-150 ms-5 ">
                    <KSRImg
                      className="object-cover"
                      logoColor="#fff"
                      lineColor="#fff"
                      ksrColor="#fff"
                      carcleanColor="#fff"
                    />
                  </button>
                </div>

                {/* PC Navigation List */}
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navList.map((path, idx) => (
                      <Link
                        className={`rounded-md px-3 py-2 text-sm font-medium duration-150 transform hover:scale-110 ${
                          isMatchPage(idx, path)
                            ? "bg-red-800 text-white"
                            : "text-red-200 hover:bg-red-800 hover:text-white"
                        }`}
                        to={path.href}
                        key={idx}>
                        {path.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              {/* === Profile - dropdown === */}
              <div className="block">
                <div className="ml-4 flex items-center md:ml-6 relative">
                  <TopButton
                    isLogin={isLogin}
                    checkLoading={checkLoading}
                    isRequireLogin={isRequireLogin}
                    userData={userData}
                    login={login}
                    navigate={navigate}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Mobile sidebar */}
          <Disclosure.Panel className="md:hidden p-6 min-h-screen">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3 flex-col ">
              {navList.map((item, idx) => (
                <Disclosure.Button
                  key={idx}
                  onClick={() => navigate(item.href)}
                  className={`${
                    isMatchPage(idx, item) ? "bg-red-900 text-white" : ""
                  } block rounded-md px-3 py-2 text-base font-medium text-red-400 hover:bg-red-700 hover:text-white`}>
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
