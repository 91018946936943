/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  convertTimestampToDate,
  formatTimeAgo,
  isInLastMinutes,
} from "../utils/datetime";
import { getUser } from "../database/user";

const defaultAvatar =
  "https://firebasestorage.googleapis.com/v0/b/ksr-car-clean.appspot.com/o/static%2Fprofile.webp?alt=media&token=9bc8afdb-b3a1-4886-a9be-851e5d601da9";

const shortText = (text, length = 14) => {
  return text.length > length ? text.slice(0, length) + "..." : text;
};

export default function LineAvatar({ user, uid, setCacheUsers }) {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(user);

  const fetchUserData = async () => {
    setIsLoading(true);
    const resGetUser = await getUser(uid);
    setIsLoading(false);

    if (resGetUser.ok && resGetUser.data) {
      setUserData(resGetUser.data);
      setCacheUsers((prev) => {
        return [...prev, resGetUser.data];
      });
    }
  };

  const getUserData = async () => {
    setCacheUsers((prev) => {
      const isExist = prev.find((user) => user?.uid === uid);
      setUserData(isExist);
      if (!isExist) {
        fetchUserData();
      }
      return prev;
    });
  };

  useEffect(() => {
    if (uid && uid !== userData?.uid) {
      getUserData();
    }
  }, [uid]);

  return (
    <div className="flex items-center gap-2 w-full min-w-max">
      <div className="relative">
        {isLoading ? (
          <div className="w-7 h-7 rounded-full bg-gray-400 animate-pulse" />
        ) : (
          <img
            className="w-7 h-7 rounded-full"
            src={userData?.line?.pictureUrl ?? defaultAvatar}
            onError={(e) => {
              e.target.src = defaultAvatar;
            }}
            onLoadStart={(e) => {
              e.target.src = defaultAvatar;
            }}
            loading="lazy"
            alt=""
          />
        )}

        {userData?.status === "inactive" ? (
          <span className="bottom-0 left-5 absolute w-3 h-3 bg-red-400 border-2 border-white rounded-full" />
        ) : isInLastMinutes(convertTimestampToDate(userData?.lastLogin), 10) ? (
          <span className="bottom-0 left-5 absolute w-3 h-3 bg-green-400 border-2 border-white rounded-full" />
        ) : null}
      </div>

      <div
        className={`font-medium text-sm text-left ${
          isLoading && " animate-pulse text-gray-400"
        }`}>
        <div>
          {isLoading
            ? "กำลังโหลด..."
            : shortText(userData?.line?.displayName ?? "ไม่ระบุชื่อ")}
        </div>
        {!isLoading ? (
          <div className="text-[0.6rem]/[0.8rem] text-gray-400 font-light ">
            {userData?.lastLogin
              ? (userData?.status === "inactive"
                  ? "ผู้ใช้บล็อก "
                  : "ออนไลน์ ") +
                formatTimeAgo(convertTimestampToDate(userData?.lastLogin))
              : "ไม่ทราบข้อมูล"}
          </div>
        ) : (
          <div className="text-[0.6rem]/[0.8rem] text-gray-400 font-light ">
            กำลังรอ
          </div>
        )}
      </div>
    </div>
  );
}
