import AllWork from "../pages/work/AllWork";
import Work from "../pages/work/Work";

const workRoutes = [
  {
    path: "",
    element: <Work />,
  },
  {
    path: ":userId",
    element: <Work />,
  },
  {
    path: "all",
    element: <AllWork />,
  },
];

export default workRoutes;
