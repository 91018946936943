import KSRImg from "../../images/KSRImg";

export default function CircleBg() {
  return (
    <div className=" absolute top-0 w-screen h-48">
      <svg width="100%" height="480" className="-translate-y-1/2 ">
        <ellipse cx="50%" cy="270" rx="60%" ry="160" fill="#B32624" />
      </svg>
      <KSRImg
        ksrColor="#fff"
        logoColor="#fff"
        lineColor="#fff"
        carcleanColor="#fff"
        className="absolute top-[70%] left-3/4 transform opacity-5 w-36 sm:w-52 -translate-x-1/2 -translate-y-full "
      />
    </div>
  );
}
