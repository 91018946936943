import { Fragment, forwardRef } from "react";

// components
import { Listbox, Transition } from "@headlessui/react";
import { FaCheck, FaChevronDown } from "react-icons/fa6";

// utils
import { getData } from "../../utils/common";

const InputSelection = forwardRef((props, ref) => {
  const { list, isOptional, property = "name" } = props;
  const [selected, setSelected] = props.selected;

  const compareDepartments = (a, b) => {
    return (
      getData(a, property)?.toLowerCase() ===
      getData(b, property)?.toLowerCase()
    );
  };

  return (
    <Listbox by={compareDepartments} value={selected} onChange={setSelected}>
      <div className="relative mt-1">
        <Listbox.Button
          ref={ref}
          className="relative w-full cursor-default rounded py-3 pl-3 pr-10 text-left shadow focus:outline-none sm:text-sm bg-gray-100 border-0 text-gray-900  focus:ring-0 focus:border-0 ">
          <span
            className={`block truncate ${
              isOptional && compareDepartments(list[0], selected)
                ? "text-gray-400"
                : ""
            }`}>
            {getData(selected, property)}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <FaChevronDown
              className="h-4 w-4 text-gray-300"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Listbox.Options className="absolute mt-1 z-30 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {list.map((item, idx) => (
              <Listbox.Option
                key={idx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active
                      ? "bg-gray-100 text-gray-900"
                      : isOptional && idx === 0
                      ? "text-gray-400"
                      : "text-gray-900"
                  }`
                }
                value={item}>
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}>
                      {getData(item, property)}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                        <FaCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
});

export default InputSelection;
