/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import { FaPhone, FaUser, FaCarRear } from "react-icons/fa6";
import { MdSpeakerNotes } from "react-icons/md";
import InputSelection from "../../components/form/InputSelection";
import InputText from "../../components/form/InputText";
import CustomInputSelection from "../../components/form/CustomInputSelection";
import AddServiceSelection from "../../components/form/AddServiceSelection";
import SelectMainService from "./components/SelectMainService";
import Header from "../../components/header/Header";

// database
import {
  mainPackageServices,
  mcMainPackageServices,
  additionalServices,
  mcAdditionalServices,
} from "../../data/service";
import { userPromotion } from "../../data/promotion";
import { createBooking } from "../../database/booking";
import { carColorList, carTypeList } from "../../data/car";

// utils
import {
  combineDateAndTime,
  generateHalfHourIntervals,
  getNextNDays,
  isTimeAfter,
} from "../../utils/datetime";
import { RootContext } from "../app/Root";
import { closeLiff } from "../../services/lineMessaging";

const DefaultPromotion = {
  id: "none",
  pid: "P0",
  name: { th: "เลือกโปรโมชั่น", en: "Select promotion." },
};

const DefaultMainService = {
  id: "none",
  pid: "P0",
  services: [],
  name: { th: "เลือกบริการหลัก", en: "Select main service." },
};

const MainServiceList = [
  DefaultMainService,
  ...mainPackageServices.flatMap((service) => service.packages),
];

const MCMainServiceList = [
  DefaultMainService,
  ...mcMainPackageServices.flatMap((service) => service.packages),
];

const AllMainServiceList = [
  ...mainPackageServices.flatMap((service) => service.packages),
  ...mcMainPackageServices.flatMap((service) => service.packages),
];

const AllAdditionalList = [...additionalServices, ...mcAdditionalServices];

const MainPromotionList = [DefaultPromotion, ...userPromotion];

export default function Booking() {
  const navigate = useNavigate();
  const rootContext = useContext(RootContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { pushToast } = rootContext.toast;
  const { openAlert } = rootContext.alert;
  const { userData, isLogin } = rootContext.user;
  const { isLoading, incLoading, decLoading, checkLoading } =
    rootContext.loading;

  let [isFirstLoad, setIsFirstLoad] = useState(true);
  let [totalPrice, setTotalPrice] = useState(0);

  let nameRef = useRef(null);
  let [name, setName] = useState("");
  let [nameError, setNameError] = useState(false);

  let phoneNumberRef = useRef(null);
  let [phoneNumber, setPhoneNumber] = useState("");
  let [phoneNumberError, setPhoneNumberError] = useState(false);

  let noteRef = useRef(null);
  let [note, setNote] = useState("");
  let [noteError, setNoteError] = useState(false);

  let licensePlateRef = useRef(null);
  let [licensePlate, setLicensePlate] = useState("");
  let [licensePlateError, setLicensePlateError] = useState(false);

  let dateRef = useRef(null);
  let [dateListMain, setDateListMain] = useState(getNextNDays(10));
  let [selectedDate, setSelectedDate] = useState(dateListMain[0]);

  // set default date to today if time is over 18:00
  useEffect(() => {
    if (isTimeAfter("17:30")) {
      const dateList = getNextNDays(10, 1);
      setDateListMain(dateList);
      setSelectedDate(dateList[0]);
    }
  }, []);

  let timeRef = useRef(null);
  let [timeListMain, setTimeListMain] = useState(
    generateHalfHourIntervals("8:30", "18:00", selectedDate.value)
  );
  let [selectedTime, setSelectedTime] = useState(timeListMain[0]);

  // set time list when date is changed
  useEffect(() => {
    const timeList = generateHalfHourIntervals(
      "8:30",
      "18:00",
      selectedDate.value
    );

    setTimeListMain(timeList);
    setSelectedTime(timeList[0]);
  }, [selectedDate]);

  let carColorRef = useRef(null);
  let [selectedCarColor, setSelectedCarColor] = useState(carColorList[0]);

  let vehicleTypeRef = useRef(null);
  let [selectedVehicleType, setSelectedVehicleType] = useState(carTypeList[0]);

  let carTypeRef = useRef(null);
  let [selectedCarType, setSelectedCarType] = useState(
    selectedVehicleType.list[0]
  );

  let promotionRef = useRef(null);
  let [selectedPromotion, setSelectedPromotion] = useState(
    MainPromotionList[0]
  );

  let serviceRef = useRef(null);
  let [serviceListMain, setServiceListMain] = useState(MainServiceList);
  let [selectedService, setSelectedService] = useState(serviceListMain[0]);

  let addServiceRef = useRef(null);
  let [additionalListMain, setAdditionalListMain] =
    useState(additionalServices);
  let [addSelectedService, setAddSelectedService] = useState([]);

  const setQuery = (key, value) => {
    if (value === "" || value === "none") {
      if (!isFirstLoad) {
        queryParams.delete(key);
      }
    } else {
      if (!isFirstLoad) {
        queryParams.set(key, value);
      }
    }
    navigate({ search: queryParams.toString() });
  };

  // set car type list and reset promotion mainservice and additional service when vehicle type is changed
  useEffect(() => {
    if (!isFirstLoad) {
      setSelectedCarType(selectedVehicleType.list[0]);
      setSelectedPromotion(DefaultPromotion);
      setSelectedService(DefaultMainService);
      setAddSelectedService([]);
    }

    switch (selectedVehicleType.id) {
      case "motorcycle":
        setServiceListMain(MCMainServiceList);
        setAdditionalListMain(mcAdditionalServices);
        break;
      case "car":
        setServiceListMain(MainServiceList);
        setAdditionalListMain(additionalServices);
        break;
    }
  }, [selectedVehicleType]);

  const calculateTotalPrice = () => {
    let price = 0;
    if (selectedService.id !== "none") {
      price += selectedService.price[selectedCarType.id];
    }

    addSelectedService.forEach((service) => {
      price += service.price[selectedCarType.id];
    });

    if (selectedPromotion.id !== "none") {
      if (selectedPromotion.type === "discount") {
        if (selectedPromotion.discount.type === "price") {
          price -= selectedPromotion.discount.value;
        } else if (selectedPromotion.discount.type === "percent") {
          price -= price * (selectedPromotion.discount.value / 100);
        }
      }
    }

    setTotalPrice(price);
  };

  // reset promotion when main service is changed
  useEffect(() => {
    if (!selectedPromotion.pids?.includes(selectedService.pid)) {
      setSelectedPromotion(DefaultPromotion);
    }
  }, [selectedService]);

  // reset additional service when main service is changed
  useEffect(() => {
    let newAddService = addSelectedService.filter(
      (service) => !selectedService.services.includes(service.name.th)
    );
    addSelectedService.forEach((service) => {
      if (selectedService.services.includes(service.name.th)) {
        setAddSelectedService(newAddService);
      }
    });
  }, [selectedService]);

  // calculate total price when main service, additional service, promotion, and car type is changed
  useEffect(() => {
    calculateTotalPrice();
  }, [selectedService, addSelectedService, selectedPromotion, selectedCarType]);

  // set query when main service is changed
  useEffect(() => {
    setQuery("ms", selectedService.id);
  }, [selectedService]);

  // set query when selected promotion is changed
  useEffect(() => {
    setQuery("promotion", selectedPromotion.id);
  }, [selectedPromotion]);

  useEffect(() => {
    setQuery("color", selectedCarColor.id);
  }, [selectedCarColor]);

  useEffect(() => {
    setQuery("type", selectedCarType.id);
  }, [selectedCarType]);

  // set query when selected additional service is changed
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const addService = addSelectedService.map((service) => service.id);
    queryParams.delete("as");
    addService.forEach((service) => {
      queryParams.append("as", service);
    });
    navigate({ search: queryParams.toString() });
  }, [addSelectedService]);

  const onChangePhoneNumberInput = (e) => {
    const newValue = e.target.value;
    const pattern = /^\d{0,10}$/;
    const isValidNewValue = pattern.test(newValue);
    if (newValue === "" || isValidNewValue) {
      setPhoneNumber(newValue);
      setPhoneNumberError(false);

      setQuery("phone", newValue);
    }
  };

  const onChangeNameInput = (e) => {
    const newValue = e.target.value;
    const pattern = /^[ก-๙a-zA-Z]+$/;
    const isValidNewValue = pattern.test(newValue);

    if (newValue === "" || isValidNewValue) {
      setName(newValue);
      setNameError(false);

      setQuery("name", newValue);
    }
  };

  const onChangeNoteInput = (e) => {
    const newValue = e.target.value;
    const isValidNewValue = newValue.length <= 50;

    if (newValue === "" || isValidNewValue) {
      setNote(newValue);
      setNoteError(false);

      setQuery("note", newValue);
    }
  };

  const onChangeLicensePlateInput = (e) => {
    const newValue = e.target.value;
    const isValidNewValue = newValue.length <= 50;

    if (newValue === "" || isValidNewValue) {
      setLicensePlate(newValue);
      setLicensePlateError(false);

      setQuery("license", newValue);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let pass = true;

    if (phoneNumber.length !== 10) {
      phoneNumberRef.current?.focus();
      setPhoneNumberError(true);
      pushToast("โปรดกรอกเบอร์โทรของคุณ!", "warn");
      pass = false;
    }

    if (selectedService.id === "none") {
      serviceRef.current?.focus();
      if (pass) {
        pushToast("โปรดเลือกบริการหลัก!", "warn");
      }
      pass = false;
    }

    if (!pass) {
      return;
    }

    const bookingDatetime = combineDateAndTime(
      selectedDate.value,
      selectedTime.value
    );

    const newBookingData = {
      note,
      customer: {
        name,
        phone: phoneNumber,
      },
      car: {
        vehicle: selectedVehicleType.id,
        type: selectedCarType.id,
        color: selectedCarColor.id,
        plate: licensePlate,
      },
      service: {
        main: selectedService,
        additional: addSelectedService,
        promotion: selectedPromotion,
      },
    };

    if (isLogin) {
      newBookingData.customer.uid = userData.uid;
    }

    incLoading("createBooking");
    const resCreateBooking = await createBooking(
      newBookingData,
      bookingDatetime
    );
    decLoading("createBooking");

    const startTime = new Date(bookingDatetime);
    startTime.setMinutes(startTime.getMinutes() - 30);

    const rangeDateTime = `${bookingDatetime.toLocaleDateString("th-TH", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })} ${startTime.toLocaleTimeString("th-TH", {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${bookingDatetime.toLocaleTimeString("th-TH", {
      hour: "2-digit",
      minute: "2-digit",
    })} น.`;

    if (resCreateBooking.ok) {
      pushToast(`จองคิวสำเร็จ`);
      openAlert(
        "จองคิวสำเร็จ",
        `โปรดนำรถมาถึงร้านใน${rangeDateTime} หากไม่มาถึงภายในเวลาดังกล่าว เราขอสงวนสิทธิ์ในการยกเลิกคิวของท่าน`,
        "ตกลง",
        () => {
          navigate(`/booking/${resCreateBooking?.id}`);
          closeLiff();
        }
      );

      return;
    } else {
      pushToast(`โปรดตรวจสอบอินเทอร์เน็ต ลองใหม่อีกครั้ง!`, "danger");
      return;
    }
  };

  const autoCompleteByQuery = () => {
    const addService = queryParams
      .getAll("as")
      .map((service) => service.toLowerCase());
    const promotion = queryParams.get("promotion")?.toLowerCase();
    const mainService = queryParams.get("ms")?.toLowerCase();
    const name = queryParams.get("name")?.toLowerCase();
    const phone = queryParams.get("phone")?.toLowerCase();
    const note = queryParams.get("note")?.toLowerCase();
    const license = queryParams.get("license")?.toLowerCase();
    const color = queryParams.get("color")?.toLowerCase();
    const carType = queryParams.get("type")?.toLowerCase();

    const mainServiceIndex = AllMainServiceList.findIndex(
      (ms) => ms.id === mainService
    );
    if (mainServiceIndex !== -1) {
      const mainService = AllMainServiceList[mainServiceIndex];
      const carPids = ["p1", "p3", "p5", "p7", "p9"];
      if (!carPids.includes(mainService.pid)) {
        setSelectedVehicleType(carTypeList[1]);
        setSelectedCarType(carTypeList[1].list[0]);
      }
      setSelectedService(mainService);
    }

    if (carType) {
      const vehicleIndex = carTypeList.findIndex((vehicle) =>
        vehicle.list.flatMap((car) => car.id).includes(carType)
      );
      const carTypeIndex = carTypeList[vehicleIndex].list.findIndex(
        (car) => car.id === carType
      );
      if (carTypeIndex !== -1) {
        setSelectedVehicleType(carTypeList[vehicleIndex]);
        setSelectedCarType(carTypeList[vehicleIndex].list[carTypeIndex]);
      }
    }

    const addServiceIndex = AllAdditionalList.filter((as) =>
      addService.includes(as.id)
    );
    if (addServiceIndex.length > 0) {
      setAddSelectedService(addServiceIndex);
    }

    const promotionIndex = MainPromotionList.findIndex(
      (p) => p.id === promotion
    );
    if (promotionIndex !== -1) {
      setSelectedPromotion(MainPromotionList[promotionIndex]);
    }

    const colorIndex = carColorList.findIndex((c) => c.id === color);
    if (colorIndex !== -1) {
      setSelectedCarColor(carColorList[colorIndex]);
    }

    if (license) {
      setLicensePlate(license);
    }

    if (name) {
      setName(name);
    }

    if (phone) {
      setPhoneNumber(phone);
    }

    if (note) {
      setNote(note);
    }
  };

  // set initial data from query params
  useEffect(() => {
    autoCompleteByQuery();

    setTimeout(() => {
      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    }, 500);
  }, []);

  return (
    <>
      <Header title="จองคิวออนไลน์" subTitle="KSR Carclean" />

      <div className=" w-full max-w-[414px] bg-white mx-auto min-h-screen pt-1">
        <div className="max-w-sm relative mt-36 mb-20 w-[22rem] mx-auto  bg-white border sm:px-4 border-gray-200 rounded-lg shadow ">
          <form noValidate className="mx-4 mb-10 " onSubmit={onSubmit}>
            <div className="mt-6 flex-col  mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลวันเวลาจอง</h2>

              <InputSelection
                ref={dateRef}
                list={dateListMain}
                selected={[selectedDate, setSelectedDate]}
              />

              <InputSelection
                ref={timeRef}
                list={timeListMain}
                selected={[selectedTime, setSelectedTime]}
              />
            </div>

            <div className="mt-6 flex-col  mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลรถ</h2>

              <CustomInputSelection
                ref={vehicleTypeRef}
                list={carTypeList}
                selected={[selectedVehicleType, setSelectedVehicleType]}
              />

              <CustomInputSelection
                ref={carTypeRef}
                list={selectedVehicleType.list}
                selected={[selectedCarType, setSelectedCarType]}
              />

              <CustomInputSelection
                ref={carColorRef}
                list={carColorList}
                selected={[selectedCarColor, setSelectedCarColor]}
              />

              <InputText
                type="text"
                placeholder="ป้ายทะเบียน"
                autoComplete="license-plate"
                Icon={FaCarRear}
                disabled={isLoading}
                onChange={onChangeLicensePlateInput}
                isError={licensePlateError}
                ref={licensePlateRef}
                value={licensePlate}
              />
            </div>

            <div className="mt-6 flex-col  mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลบริการ</h2>

              <SelectMainService
                ref={serviceRef}
                list={serviceListMain}
                selected={[selectedService, setSelectedService]}
                keySpan={selectedCarType.id}
              />

              {/* <CustomInputSelection
                isOptional
                ref={serviceRef}
                list={serviceListMain}
                selected={[selectedService, setSelectedService]}
                keySpan={selectedCarType.id}
              /> */}

              <AddServiceSelection
                ref={addServiceRef}
                list={additionalListMain}
                setList={setAdditionalListMain}
                selected={[addSelectedService, setAddSelectedService]}
                selectedCarType={selectedCarType}
                selectedService={selectedService}
              />

              <CustomInputSelection
                isOptional
                ref={promotionRef}
                list={MainPromotionList}
                selected={[selectedPromotion, setSelectedPromotion]}
                KeyDisabled={selectedService.pid}
              />

              <h2 className=" text-xs text-right font-bold ">
                รวม{" "}
                {totalPrice.toLocaleString("th-TH", {
                  style: "currency",
                  currency: "THB",
                })}
              </h2>
            </div>

            <div className="mt-6 flex-col max-w-sm mx-auto space-y-3 justify-end">
              <h2 className=" text-sm font-bold ">ข้อมูลผู้จอง</h2>

              <InputText
                type="text"
                autoComplete="name"
                placeholder="ชื่อเล่น"
                Icon={FaUser}
                value={name}
                ref={nameRef}
                onChange={onChangeNameInput}
                disabled={isLoading}
                isError={nameError}
              />

              <InputText
                type="tel"
                autoComplete="tel"
                pattern="(0)\d{8,9}"
                inputMode="numeric"
                placeholder="เบอร์โทร *"
                Icon={FaPhone}
                value={phoneNumber}
                onChange={onChangePhoneNumberInput}
                ref={phoneNumberRef}
                disabled={isLoading}
                isError={phoneNumberError}
                required
              />

              <InputText
                type="text"
                autoComplete="note"
                placeholder="หมายเหตุ"
                Icon={MdSpeakerNotes}
                value={note}
                ref={noteRef}
                onChange={onChangeNoteInput}
                disabled={isLoading}
                isError={noteError}
                required
              />
            </div>

            <div className="justify-center flex mt-5">
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-mainred px-4 py-3 w-full text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:animate-pulse"
                type="submit"
                disabled={checkLoading("createBooking")}>
                {checkLoading("createBooking") ? "กำลังโหลด..." : "จองคิว"}
              </button>
            </div>

            <div className=" pt-3 ">
              <p className=" text-center text-gray-400 text-xs">
                ร้านเคเอสอาร์ คาร์คลีน
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
