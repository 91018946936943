import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: "ksr-car-clean.firebaseapp.com",
  projectId: "ksr-car-clean",
  storageBucket: "ksr-car-clean.appspot.com",
  messagingSenderId: "98004660625",
  appId: "1:98004660625:web:d3f63dbee01a1e81b8ea30",
  measurementId: "G-C89V1F8LFX",
};

const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);
export const authentication = getAuth(firebase);
export const storage = getStorage(firebase);
export default firebase;
