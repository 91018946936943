import { Fragment, useState, useRef, forwardRef, useContext } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RootContext } from "../../pages/app/Root";

// icons
import { FaCheck, FaChevronDown } from "react-icons/fa6";
import { IoIosAddCircle } from "react-icons/io";
import { thaiToEnglish } from "../../utils/common";

const AddServiceSelection = forwardRef((props, ref) => {
  const rootContext = useContext(RootContext);
  const { addAble } = props;

  const { pushToast } = rootContext.toast;
  const { isLoading } = rootContext.loading;

  const { list, setList, selectedCarType, selectedService } = props;
  const [listSelected, setListSelected] = props.selected;

  const [isAddMode, setIsAddMode] = useState(false);

  let serviceRef = useRef(null);
  const [service, setService] = useState("");
  const [serviceError, setServiceError] = useState(false);

  let priceRef = useRef(null);
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(false);

  const onChangePriceInput = (e) => {
    const newValue = e.target.value;

    if (isNaN(newValue)) {
      setPriceError(true);
      return;
    }

    setPrice(newValue);
    setPriceError(false);
  };

  const onChangeServiceInput = (e) => {
    const newValue = e.target.value;
    setService(newValue);
    setServiceError(false);
  };

  const compareDepartments = (a, b) => {
    return a.id.toLowerCase() === b.id.toLowerCase();
  };

  const selectedText = () => {
    if (listSelected.length > 0) {
      return listSelected.map((item) => item.name.th).join(", ");
    } else {
      return "เลือกบริการเสริม";
    }
  };

  const addService = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let pass = true;

    if (service === "") {
      serviceRef.current?.focus();
      pass = false;
      pushToast("โปรดกรอกชื่อบริการ!", "warn");
      setServiceError(true);
    }

    if (price === "") {
      priceRef.current?.focus();
      pass = false;
      pushToast("โปรดกรอกราคาบริการ!", "warn");
      setPriceError(true);
    }

    if (!pass) return;

    const carPrice = {
      sedan: price,
      pickup: price,
      suv: price,
      mpv: price,
    };

    const mcPrice = {
      motorbike: price,
      minibike: price,
      bigbike: price,
    };

    const carTypeList = ["sedan", "pickup", "suv", "mpv"];
    const isCarType = carTypeList.includes(selectedCarType.id);

    const newService = {
      id: `AS${thaiToEnglish(service)}`,
      name: { th: service },
      price: isCarType ? carPrice : mcPrice,
    };

    const newList = [...list, newService];
    setList(newList);
    setListSelected([...listSelected, newService]);
    setService("");
    setPrice("");
    setIsAddMode(false);
  };

  return (
    <Listbox
      by={compareDepartments}
      value={listSelected}
      onChange={setListSelected}
      multiple>
      <div className="relative mt-1">
        <Listbox.Button
          ref={ref}
          className="relative w-full cursor-default rounded py-3 pl-3 pr-10 text-left shadow focus:outline-none sm:text-sm bg-gray-100 border-0 text-gray-900  focus:ring-0 focus:border-0 ">
          <span
            className={`block truncate ${
              listSelected.length === 0 ? "text-gray-400" : ""
            }`}>
            {selectedText()}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <FaChevronDown
              className="h-4 w-4 text-gray-300"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Listbox.Options className="absolute mt-1 z-30 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            <ul className="h-48 pb-3 overflow-y-auto text-sm text-gray-700 ">
              {list.map((item, idx) => (
                <Listbox.Option
                  disabled={selectedService.services.includes(item?.name.th)}
                  key={idx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-gray-100 text-gray-900" : "text-gray-900"
                    }`
                  }
                  value={item}>
                  {({ selected }) => (
                    <>
                      <p
                        className={`block truncate ${
                          selected
                            ? "font-medium"
                            : selectedService.services.includes(item?.name.th)
                            ? "text-gray-400"
                            : "font-normal"
                        }`}>
                        {item?.name.th}
                        <span className="text-gray-300 ml-2 text-xs">
                          {item.price[selectedCarType.id]}฿
                        </span>
                      </p>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                          <FaCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </ul>
            {addAble &&
              (!isAddMode ? (
                <button
                  onClick={() => setIsAddMode(true)}
                  className="flex items-center p-3 text-sm font-medium border-t border-gray-200 rounded-b-lg bg-gray-50  hover:bg-gray-100 hover:underline w-full">
                  <IoIosAddCircle className="w-4 h-4 me-2 " />
                  เพิ่มบริการเสริมอื่นๆ
                </button>
              ) : (
                <div className="flex items-center w-full ">
                  <div className="relative">
                    <input
                      type="text"
                      autoComplete="service"
                      className={`${
                        serviceError
                          ? "bg-red-50 border-0 text-red-900 placeholder-red-700 focus:placeholder-red-300  focus:ring-0 "
                          : "bg-gray-100 border-0 text-gray-900 placeholder-gray-500 focus:placeholder-gray-300 focus:ring-0 "
                      }   block w-full text-sm  p-3`}
                      placeholder="บริการ *"
                      value={service}
                      ref={serviceRef}
                      onChange={onChangeServiceInput}
                      disabled={isLoading}
                      required
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="number"
                      autoComplete="service"
                      inputMode="numeric"
                      className={`${
                        priceError
                          ? "bg-red-50 border-0 text-red-900 placeholder-red-700 focus:placeholder-red-300  focus:ring-0 "
                          : "bg-gray-100 border-0 text-gray-900 placeholder-gray-500 focus:placeholder-gray-300 focus:ring-0 "
                      }   block w-full text-sm p-3 `}
                      placeholder="ราคา *"
                      value={price}
                      ref={priceRef}
                      onChange={onChangePriceInput}
                      disabled={isLoading}
                      required
                    />
                  </div>
                  <button
                    onClick={addService}
                    className="flex items-center p-3 text-sm font-medium bg-gray-100 hover:bg-gray-200">
                    <IoIosAddCircle className="w-4 h-4 me-2 " />
                    เพิ่ม
                  </button>
                </div>
              ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
});

export default AddServiceSelection;
