/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";

// components
import { FaCar, FaPen } from "react-icons/fa6";
import { MdMonetizationOn } from "react-icons/md";
import Header from "../../components/header/Header";
import Table from "../../components/table/Table";
import MainBox from "../../components/layout/MainBox";
import InputSelection from "../../components/form/InputSelection";

// database
import { realtimeGetBills } from "../../database/bill";

// utils
import { RootContext } from "../app/Root";
import { getNDayList } from "../../utils/datetime";

const DateListMain = getNDayList(60, 0);

const BillTableConfig = [
  {
    head: "เวลารถเข้า",
    type: "time",
    data: "start",
  },
  {
    head: "ประเภทรถ",
    type: "text",
    data: "car.type",
  },
  {
    head: "สีรถ",
    type: "text",
    data: "car.color",
  },
  {
    head: "ทะเบียน",
    type: "text",
    data: "car.plate",
  },
  {
    head: "บริการหลัก",
    type: "text",
    data: "service.main.name.th",
  },
  {
    head: "บริการเพิ่มเติม",
    type: "list",
    data: "service.additional",
    inner: "name.th",
  },

  {
    head: "หมายเหตุ",
    type: "text",
    data: "note",
  },
  {
    head: "ยอดเงินรวม",
    type: "text",
    data: "payment.total",
  },
  {
    head: "การชำระเงิน",
    type: "text",
    data: "payment.type",
  },
  {
    head: "ส่วนลด",
    type: "discount",
    data: "payment.discount",
  },
  {
    head: "ยอดเงินที่ต้องชำระ",
    type: "text",
    data: "payment.amount",
  },
  {
    head: "รหัสบิล",
    type: "text",
    data: "id",
  },
  {
    head: "สถานะ",
    type: "text",
    data: "status",
  },
];

export default function AllBill() {
  const rootContext = useContext(RootContext);

  const { isLogin, userData } = rootContext.user;
  const { incLoading, decLoading } = rootContext.loading;

  let dateRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(DateListMain[10]);

  const [bills, setBills] = useState(null);
  const [displayBills, setDisplayBills] = useState([]);

  useEffect(() => {
    if (isLogin && userData?.type === "employee") {
      incLoading("realtimeGetBills");
      realtimeGetBills(setBills, "all");
    }
  }, [isLogin]);

  useEffect(() => {
    if (bills) {
      decLoading("realtimeGetBills");
      setDisplayBills(bills);
    }
  }, [bills]);

  return (
    <div className=" min-h-screen">
      <Header
        title="รายการบิล"
        requireType="employee"
        userType={userData?.type}
      />

      <div className="pt-36 pb-20">
        <MainBox>
          <InputSelection
            ref={dateRef}
            list={DateListMain}
            selected={[selectedDate, setSelectedDate]}
          />
        </MainBox>

        <MainBox>
          <Table
            pathAdd="/bill/add"
            data={displayBills}
            config={BillTableConfig}
            option={{
              head: {
                type: "label",
                data: "car.plate",
                label: "ทะเบียน",
              },
              list: [
                {
                  icon: FaPen,
                  text: "แก้ไขข้อมูล",
                },
                {
                  icon: MdMonetizationOn,
                  text: "ชำระเงิน",
                },
                {
                  icon: FaCar,
                  text: "รับรถคืน",
                },
              ],
            }}
          />
        </MainBox>
      </div>
    </div>
  );
}
